// src/types/agent.js

export const AGENT_TYPES = {
  VOICE: 'voice',  // Changed to lowercase to match backend conventions
  CHAT: 'chat',
  EMAIL: 'email'
};

export const AGENT_DIRECTIONS = {
  INBOUND: 'inbound',
  OUTBOUND: 'outbound'
};

// Reference models for shape of data
export const Agent = {
  id: 'string',
  name: 'string',
  type: 'string',
  funnel: 'string', // funnel ID
  campaign: 'string', // campaign ID
  position: 'number',
  direction: 'string',
  event_triggered: 'boolean',
  schedule_interval: 'string',
  schedule_time: 'string',
  integration_settings: 'object',
  // Type-specific fields
  bland_ai_pathway: 'string',
  voice_script: 'string',
  twilio_conversation_id: 'string',
  chat_script: 'string',
  mailchimp_campaign_id: 'string',
  email_script: 'string'
};