// src/components/LeadsManagement.js

import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Button, Stack, Select, MenuItem, Snackbar, Box,
  CircularProgress, FormControl, InputLabel,
  Alert, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText,
  Grid, ListItemIcon, ListItemText, Menu
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { api } from '../services/authService';
import './LeadsManagement.css';
import { useTheme } from '@mui/material/styles';
import Papa from 'papaparse';
import dayjs from 'dayjs';

import useLocalStorage from '../hooks/useLocalStorage';

import BulkActionsMenu from '../components/BulkActionsMenu';
import ErrorModal from '../components/ErrorModal';
import LeadsTable from '../components/LeadsTable';
import CustomDateEditCell from '../components/CustomDateEditCell';
import ConfirmDialog from '../components/ConfirmDialog';

const LeadsManagement = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.user);

  // Basic state
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useLocalStorage('selectedAccount', '');
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useLocalStorage('selectedCampaign', '');
  const [campaignDetails, setCampaignDetails] = useState(null);
  const [funnels, setFunnels] = useState([]);
  const [selectedFunnel, setSelectedFunnel] = useLocalStorage('selectedFunnel', '');
  const [campaignMembers, setCampaignMembers] = useState([]);
  const [leadStatuses, setLeadStatuses] = useState([]);
  const [funnelSteps, setFunnelSteps] = useState([]);
  const [leads, setLeads] = useState([]);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [dynamicFields, setDynamicFields] = useState([]);

  // Menus & dialogs
  const [moreActionsAnchorEl, setMoreActionsAnchorEl] = useState(null);
  const [bulkActionsAnchorEl, setBulkActionsAnchorEl] = useState(null);
  const [assignFunnelAnchorEl, setAssignFunnelAnchorEl] = useState(null);
  const [actionsAnchorEl, setActionsAnchorEl] = useState(null);
  const [currentLead, setCurrentLead] = useState(null);

  const [loading, setLoading] = useState(false);
  const [bulkActionLoading, setBulkActionLoading] = useState(false);

  // Pagination, sorting, editing
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalLeads, setTotalLeads] = useState(0);
  const [orderBy, setOrderBy] = useState('created_at');
  const [order, setOrder] = useState('desc');
  const [editingCell, setEditingCell] = useState(null);

  // We rely on Data Grid's filter model for server-side filtering
  // but we add an extra "debouncedFilterModel" to avoid immediate requests.
  const [filterModel, setFilterModel] = useState({
    items: [],
    quickFilterValues: [],
  });
  const [debouncedFilterModel, setDebouncedFilterModel] = useState(filterModel);

  // For CSV import
  const fileInputRef = useRef(null);
  const [importErrors, setImportErrors] = useState([]);
  const [failedRowsData, setFailedRowsData] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);

  // Confirmation dialogs
  const [bulkDeleteConfirmationOpen, setBulkDeleteConfirmationOpen] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [leadToConfirm, setLeadToConfirm] = useState(null);
  const [warningMessage, setWarningMessage] = useState([]);

  // Some regex for phone/email
  const phoneNumberRegex = /^\d{3}-\d{3}-\d{4}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Additional loading flags
  const [accountsLoading, setAccountsLoading] = useState(true);
  const [campaignsLoading, setCampaignsLoading] = useState(true);

  // Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    leadId: null,
    isLoading: false
  });

  // Add state to track new lead data
  const [newLeadData, setNewLeadData] = useState(null);

  /**
   * 1) Debounce the filter model changes so we don't refetch on every keystroke.
   */
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedFilterModel(filterModel);
    }, 500); // adjust the debounce (ms) as needed
    return () => clearTimeout(timer);
  }, [filterModel]);

  /**
   * 2) Whenever debouncedFilterModel changes (along with page, etc.), fetch leads.
   */
  useEffect(() => {
    if (selectedCampaign) {
      fetchLeads();
    } else {
      setLeads([]);
      setTotalLeads(0);
    }
  }, [selectedCampaign, selectedFunnel, page, rowsPerPage, orderBy, order, debouncedFilterModel]);

  // FETCH ACCOUNTS
  useEffect(() => {
    const fetchAccounts = async () => {
      setAccountsLoading(true);
      try {
        const response = await api.get('/crm/accounts/');
        setAccounts(response.data);
      } catch (error) {
        console.error('Error fetching accounts:', error);
      } finally {
        setAccountsLoading(false);
      }
    };
    fetchAccounts();
  }, []);

  // Validate selectedAccount
  useEffect(() => {
    if (!accountsLoading && selectedAccount) {
      const accountExists = accounts.some((a) => String(a.id) === String(selectedAccount));
      if (!accountExists) {
        setSelectedAccount('');
      }
    }
  }, [accountsLoading, accounts, selectedAccount]);

  // Fetch campaigns
  useEffect(() => {
    const fetchCampaigns = async () => {
      if (selectedAccount) {
        setCampaignsLoading(true);
        try {
          const response = await api.get(`/crm/campaigns/?account_id=${selectedAccount}`);
          setCampaigns(response.data);
        } catch (error) {
          console.error('Error fetching campaigns:', error);
        } finally {
          setCampaignsLoading(false);
        }
      } else {
        setCampaigns([]);
      }
    };
    fetchCampaigns();
  }, [selectedAccount]);

  // Validate selectedCampaign
  useEffect(() => {
    if (!campaignsLoading && selectedCampaign) {
      if (campaigns.length > 0) {
        const campaignExists = campaigns.some((c) => String(c.id) === String(selectedCampaign));
        if (!campaignExists) {
          setSelectedCampaign('');
        }
      } else {
        setSelectedCampaign('');
      }
    }
  }, [campaignsLoading, campaigns, selectedCampaign]);

  // Fetch lead statuses
  useEffect(() => {
    const fetchLeadStatuses = async () => {
      try {
        const response = await api.get('/crm/lead-statuses/');
        setLeadStatuses(response.data);
      } catch (error) {
        console.error('Error fetching lead statuses:', error);
      }
    };
    fetchLeadStatuses();
  }, []);

  // Fetch funnels
  useEffect(() => {
    const fetchFunnels = async () => {
      if (selectedCampaign) {
        try {
          const response = await api.get(`/crm/funnels/?campaign_id=${selectedCampaign}`);
          setFunnels(response.data);
        } catch (error) {
          console.error('Error fetching funnels:', error);
        }
      } else {
        setFunnels([]);
      }
    };
    fetchFunnels();
  }, [selectedCampaign]);

  // Fetch funnel steps
  useEffect(() => {
    const fetchFunnelSteps = async () => {
      if (selectedCampaign) {
        try {
          const response = await api.get(
            `/crm/steps/?funnel_id=${selectedFunnel}&campaign_id=${selectedCampaign}`
          );
          setFunnelSteps(response.data);
        } catch (error) {
          console.error('Error fetching funnel steps:', error);
        }
      } else {
        setFunnelSteps([]);
      }
    };
    fetchFunnelSteps();
  }, [selectedFunnel, selectedCampaign]);

  // Fetch campaign details + dynamic fields
  useEffect(() => {
    if (selectedCampaign && selectedAccount) {
      fetchCampaignDetails(selectedAccount, selectedCampaign);
      fetchDynamicFields(selectedCampaign);
    } else {
      setCampaignDetails(null);
      setCampaignMembers([]);
      setDynamicFields([]);
    }
  }, [selectedCampaign, selectedAccount]);

  const lastParamsRef = useRef('');

  /**
   * The main fetch of leads, invoked after the "debounce" completes.
   */
  const fetchLeads = async () => {
    if (!selectedCampaign) return;
  
    setLoading(true);
    try {
      // 1. Build the final query object (including filter, pagination, sorting, funnel, etc.)
      const filterParams = parseFilterModel(debouncedFilterModel);
  
      const finalParams = {
        campaign_id: selectedCampaign,
        funnel_id: selectedFunnel || null,
        page: page + 1, // 1-based
        page_size: rowsPerPage,
        ordering: order === 'desc' ? `-${orderBy}` : orderBy,
        ...filterParams,
      };
  
      // 2. Stringify to compare
      const paramString = JSON.stringify(finalParams);
  
      // 3. Compare with lastParamsRef
      if (paramString === lastParamsRef.current) {
        // identical params => skip fetch
        // console.log('Skipping fetch, unchanged final params:', paramString);
        setLoading(false);
        return;
      }
      lastParamsRef.current = paramString;
  
      // 4. Actually fetch from server
      const response = await api.get('/crm/leads/', { params: finalParams });
  
      // 5. Process results
      setLeads(
        response.data.results.map((lead) => ({
          ...lead,
          field_values: (lead.field_values || []).map((fv) => {
            const fieldDefinitionId = fv.field_definition;
            const fieldDefinition = dynamicFields.find(
              (field) => field.id === fieldDefinitionId
            );
            return {
              field_definition: fieldDefinitionId,
              field_api_name: fieldDefinition ? fieldDefinition.api_name : '',
              value: fv.value,
            };
          }),
        }))
      );
      setTotalLeads(response.data.count || 0);
  
    } catch (error) {
      console.error('Error fetching leads:', error);
      showSnackbar('Failed to fetch leads', 'error');
      setLeads([]);
      setTotalLeads(0);
    }
    setLoading(false);
  };

  /**
   * parseFilterModel: transform Data Grid's filter model into query params
   * e.g. { first_name__icontains: 'john', q: 'some quick text' }
   */
  const parseFilterModel = (fm) => {
    if (!fm) return {};

    const result = {};
    const { items = [], quickFilterValues = [] } = fm;

    // Column-based filters
    items.forEach((item) => {
      // Check if we have valid filter data
      if (!item || !item.field) return;

      const { field, operator, value } = item;
      
      // Skip if no value to filter by
      if (value === undefined || value === null || value === '') return;

      // Map DataGrid operators to Django filter suffixes
      switch (operator) {
        case 'contains':
          result[`${field}__icontains`] = value;
          break;
        case 'equals':
          result[field] = value;
          break;
        case 'startsWith':
          result[`${field}__istartswith`] = value;
          break;
        case 'endsWith':
          result[`${field}__iendswith`] = value;
          break;
        case 'isEmpty':
          result[`${field}__isnull`] = true;
          break;
        case 'isNotEmpty':
          result[`${field}__isnull`] = false;
          break;
        default:
          // console.log('Unhandled filter operator:', operator);
          break;
      }
    });

    // Quick filter text
    if (quickFilterValues && quickFilterValues.length > 0) {
      const quickText = quickFilterValues.join(' ');
      result.q = quickText;
    }

    // console.log('Parsed filter params:', result); // Debug log
    return result;
  };

  // SNACKBAR
  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  };

  // Additional fetch logic for campaign details, dynamic fields
  const fetchCampaignDetails = async (accountId, campaignId) => {
    try {
      const response = await api.get(`/crm/campaigns/${campaignId}/?account_id=${accountId}`);
      setCampaignMembers(response.data.users_with_access);
      setCampaignDetails(response.data);
    } catch (error) {
      console.error('Error fetching campaign details:', error);
    }
  };
  const fetchDynamicFields = async (campaignId) => {
    try {
      const response = await api.get(`/crm/campaigns/${campaignId}/selected_lead_fields/`);
      const { lead_field_definitions } = response.data;
      setDynamicFields(lead_field_definitions);
    } catch (error) {
      console.error('Error fetching dynamic fields:', error);
    }
  };

  // handleFilterChange from Data Grid
  const handleFilterChange = (newFilterModel) => {
    // console.log('Filter model changed:', newFilterModel);
    setFilterModel(newFilterModel);
    setPage(0); // Reset to first page when filters change
  };
  

  // Sorting
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Selections
  const handleLeadSelect = (newSelection) => {
    // console.log('Selected leads:', newSelection);
    setSelectedLeads(newSelection);
  };

  // Account/Campaign/Funnel changes
  const handleAccountChange = (e) => {
    const newAccountId = e.target.value;
    if (newAccountId !== selectedAccount) {
      setSelectedAccount(newAccountId);
      setSelectedCampaign('');
      setSelectedFunnel('');
      setPage(0);
    }
  };
  const handleCampaignChange = (e) => {
    const newCampaignId = e.target.value;
    if (newCampaignId !== selectedCampaign) {
      setSelectedCampaign(newCampaignId);
      setSelectedFunnel('');
      setPage(0);
    }
  };
  const handleFunnelChange = (e) => {
    setSelectedFunnel(e.target.value);
    setPage(0);
  };

  // Bulk action logic
  const handleBulkDelete = () => {
    setBulkDeleteConfirmationOpen(true);
    handleBulkActionsClose();
  };

  const confirmBulkDelete = async () => {
    setBulkDeleteConfirmationOpen(false);
    await handleBulkAction('delete');
  };
  
  const handleBulkAction = async (action, funnelId = null) => {
    setBulkActionLoading(true);
    try {
      if (action === 'assign') {
        if (!funnelId) {
          showSnackbar('Please select a funnel to assign.', 'error');
          return;
        }
        await api.post('/crm/leads/bulk_assign_funnel/', {
          ids: rowSelectionModel,
          funnel_id: funnelId,
        });
        showSnackbar('Leads assigned to funnel successfully.', 'success');
      } else if (action === 'delete') {
        await api.post('/crm/leads/bulk_delete/', { ids: rowSelectionModel });
        showSnackbar('Leads deleted successfully.', 'success');
      }
      setRowSelectionModel([]);
      fetchLeads();
    } catch (error) {
      console.error('Error performing bulk action:', error);
      showSnackbar('Failed to perform bulk action.', 'error');
    } finally {
      setBulkActionLoading(false);
    }
  };

  // Update handleCellChange to handle both direct values and field objects
  const handleCellChange = useCallback(
    async (leadId, updates, fieldDefinitionId = null) => {
      let finalUpdates = null;

      setLeads((prevLeads) =>
        prevLeads.map((lead) => {
          if (lead.id !== leadId) {
            return lead;
          }
  
          // console.log('lead:', lead);
          // console.log('updates:', updates);
  
          // 1) Merge top-level updates (e.g. first_name, status, current_step, etc.)
          let updatedLead = { ...lead, ...updates };
  
          // 2) Handle "special" fields that generate display names
          if (updates.current_step !== undefined) {
            const selectedStep = funnelSteps.find((s) => s.id === updates.current_step);
            updatedLead.current_step_name = selectedStep ? selectedStep.name : null;
          }
          if (updates.status !== undefined) {
            const selectedStatus = leadStatuses.find((s) => s.id === updates.status);
            updatedLead.status_name = selectedStatus ? selectedStatus.name : null;
          }
  
          // 3) Prepare to merge dynamic fields
          const dynamicFieldValues = [];
          Object.keys(updates).forEach((key) => {
            if (key.startsWith('dynamic_')) {
              const apiName = key.replace('dynamic_', '');
              const fieldDefinition = dynamicFields.find(
                (df) => df.api_name === apiName
              );
              if (fieldDefinition) {
                dynamicFieldValues.push({
                  field_definition: fieldDefinition.id,
                  field_api_name: apiName,
                  value: updates[key],
                });
              } else {
                console.error(`Field definition not found for apiName: ${apiName}`);
              }
            }
          });
  
          // 4) Combine all field values
          let combinedFieldValues = [];
          if (updates.field_values && Array.isArray(updates.field_values)) {
            combinedFieldValues = [...updates.field_values];
          }
          if (dynamicFieldValues.length > 0) {
            combinedFieldValues = [...combinedFieldValues, ...dynamicFieldValues];
          }
  
          // 5) Process field values
          if (combinedFieldValues.length > 0) {
            const fieldValuesCopy = updatedLead.field_values
              ? [...updatedLead.field_values]
              : [];
  
            combinedFieldValues.forEach((fv) => {
              const { field_api_name, value, field_definition } = fv;
              const localFieldDefId = fieldDefinitionId || field_definition;
  
              // Check if this field API name already exists
              const existingIndex = fieldValuesCopy.findIndex(
                (item) => item.field_api_name === field_api_name
              );
              if (existingIndex >= 0) {
                fieldValuesCopy[existingIndex] = {
                  ...fieldValuesCopy[existingIndex],
                  value,
                  field_definition: localFieldDefId,
                };
              } else {
                // Push new
                fieldValuesCopy.push({
                  field_api_name,
                  value,
                  field_definition: localFieldDefId,
                });
              }
            });
  
            updatedLead.field_values = fieldValuesCopy;
          }
  
          // If this is a new lead, update the newLeadData state instead of sending to server
          if (lead.isNew) {
            setNewLeadData(prev => ({
              ...prev,
              ...updatedLead,
              field_values: updatedLead.field_values
            }));
            return updatedLead;
          }
  
          // For existing leads, continue with the normal update process
          finalUpdates = {
            ...updates,
            field_values: updatedLead.field_values
          };
  
          return updatedLead;
        })
      );
  
      // Only send updates to server if it's not a new lead
      if (leadId !== 'new') {
        try {
          await api.patch(`/crm/leads/${leadId}/`, finalUpdates);
          showSnackbar('Lead updated successfully', 'success');
        } catch (error) {
          console.error('Error updating lead:', error);
          showSnackbar('Failed to update lead', 'error');
        }
      }
    },
    [funnelSteps, leadStatuses, dynamicFields, showSnackbar]
  );  

  const handleCellClick = useCallback((leadId, columnId) => {
    setEditingCell({ leadId, columnId });
  }, []);

  // lead editing
  const handleSave = async (leadId) => {
    try {
      const leadToUpdate = leads.find((l) => l.id === leadId);
      if (!leadToUpdate) return;

      const isPhoneValid = phoneNumberRegex.test(leadToUpdate.phone_number || '');
      const isEmailValid = emailRegex.test(leadToUpdate.email || '');

      if (!isPhoneValid || !isEmailValid) {
        const warnings = [];
        if (!isPhoneValid) {
          warnings.push('Invalid phone number format. Expected format: XXX-XXX-XXXX.');
        }
        if (!isEmailValid) {
          warnings.push('Invalid email address format. Expected format: example@example.com');
        }
        setWarningMessage(warnings);
        setLeadToConfirm(leadToUpdate);
        setOpenConfirmation(true);
        return;
      }
      await saveLead(leadToUpdate);
    } catch (error) {
      console.error('Error saving lead:', error);
      showSnackbar('Failed to save lead', 'error');
    }
  };
  const saveLead = async (leadToUpdate) => {
    try {
      const validFieldValues = (leadToUpdate.field_values || []).filter(
        (fv) => fv.field_definition
      );
      const payload = {
        ...leadToUpdate,
        campaign: selectedCampaign,
        funnel: selectedFunnel,
        last_updated_by: currentUser.id,
        lead_type: campaignDetails ? campaignDetails.campaign_model.name.toLowerCase() : '',
        current_step: leadToUpdate.current_step?.id || leadToUpdate.current_step,
        status: leadToUpdate.status?.id || leadToUpdate.status,
        assigned_to: leadToUpdate.assigned_to?.id || leadToUpdate.assigned_to,
        field_values: validFieldValues.map((fv) => ({
          field_definition: fv.field_definition,
          value: fv.value,
        })),
      };
      delete payload.id;
      delete payload.field_name;
      delete payload.field_api_name;
      delete payload.current_step_name;

      if (leadToUpdate.lead_type === 'b2b') {
        payload.company = leadToUpdate.company || null;
        payload.job_title = leadToUpdate.job_title || null;
        payload.industry = leadToUpdate.industry || null;
        payload.company_size = leadToUpdate.company_size || null;
        payload.annual_revenue = leadToUpdate.annual_revenue || null;
      } else if (leadToUpdate.lead_type === 'd2c') {
        payload.date_of_birth = leadToUpdate.date_of_birth || null;
        payload.age = leadToUpdate.age || null;
        payload.gender = leadToUpdate.gender || null;
        payload.marital_status = leadToUpdate.marital_status || null;
      }

      if (leadToUpdate.id === 'new') {
        await api.post('/crm/leads/', payload);
        showSnackbar('New lead added successfully', 'success');
      } else {
        await api.put(`/crm/leads/${leadToUpdate.id}/`, payload);
        showSnackbar('Lead updated successfully', 'success');
      }
      setEditingCell(null);
      fetchLeads();
    } catch (error) {
      console.error('Error saving lead:', error);
      showSnackbar('Failed to save lead', 'error');
    }
  };
  const handleConfirmSave = async () => {
    if (leadToConfirm) {
      await saveLead(leadToConfirm);
    }
    setOpenConfirmation(false);
  };
  const handleCancelSave = () => {
    setOpenConfirmation(false);
  };
  const handleCancel = () => {
    if (editingCell?.leadId === 'new') {
      // Remove the new lead from the list if canceling
      setLeads(prevLeads => prevLeads.filter(lead => lead.id !== 'new'));
      setNewLeadData(null);
    }
    setEditingCell(null);
    fetchLeads();
  };
  const handleDelete = async (id) => {
    try {
      await api.delete(`/crm/leads/${id}/`);
      showSnackbar('Lead deleted successfully', 'success');
      fetchLeads();
    } catch (error) {
      console.error('Error deleting lead:', error);
      showSnackbar('Failed to delete lead', 'error');
    }
  };
  const handleAddRow = () => {
    const leadType = campaignDetails ? campaignDetails.campaign_model.name : '';
    const newLead = {
      id: 'new',
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      current_step: '',
      status: '',
      assigned_to: '',
      field_values: [],
      lead_type: leadType.toLowerCase(),
      isNew: true, // Flag to identify new leads
      ...(leadType === 'B2B' && {
        company: '',
        job_title: '',
        industry: '',
        company_size: '',
        annual_revenue: '',
      }),
      ...(leadType === 'D2C' && {
        date_of_birth: '',
        age: '',
        gender: '',
        marital_status: '',
      }),
    };
    setNewLeadData(newLead); // Store initial new lead data
    setLeads([newLead, ...leads]);
    setEditingCell({ leadId: 'new', columnId: 'first_name' });
  };

  // Export, import, etc...
  const handleExport = async () => {
    if (!selectedCampaign) {
      showSnackbar('Please select a campaign before exporting', 'error');
      return;
    }
    try {
      setLoading(true);
      const requestBody = { campaign_id: selectedCampaign };
      if (selectedFunnel) {
        requestBody.funnel_id = selectedFunnel;
      }
      const response = await api.post('/crm/lead_data/', requestBody, {
        responseType: 'blob',
      });
      const campaign = campaigns.find((t) => t.id === selectedCampaign);
      const funnel = funnels.find((f) => f.id === selectedFunnel);
      const campaignName = campaign ? campaign.name.replace(/\s+/g, '_') : 'unknown_campaign';
      const funnelName = funnel ? funnel.name.replace(/\s+/g, '_') : 'all_funnels';

      const now = new Date();
      const timestamp = now
        .toISOString()
        .replace(/[-:]/g, '')
        .replace('T', '_')
        .slice(0, 15);

      const fileName = `leads_${campaignName}_${funnelName}_${timestamp}.csv`;
      const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      showSnackbar('Leads exported successfully', 'success');
    } catch (error) {
      console.error('Error exporting leads:', error);
      showSnackbar('Failed to export leads', 'error');
    } finally {
      setLoading(false);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };
  const handleImport = async (event) => {
    if (!selectedCampaign) {
      showSnackbar('Please select a campaign before importing', 'error');
      return;
    }
    const file = event.target.files[0];
    if (!file) {
      showSnackbar('No file selected', 'error');
      return;
    }
    if (!file.name.endsWith('.csv')) {
      showSnackbar('Please upload a CSV file', 'error');
      return;
    }
    let parsedData = [];
    await new Promise((resolve) => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          parsedData = results.data;
          resolve();
        },
        error: (err) => {
          console.error('Error parsing CSV:', err);
          showSnackbar('Failed to parse CSV file', 'error');
          resolve();
        },
      });
    });
    if (!parsedData || parsedData.length === 0) {
      showSnackbar('No data found in CSV file', 'error');
      return;
    }
    const formData = new FormData();
    formData.append('file', file);
    formData.append('campaign_id', selectedCampaign);
    formData.append('funnel_id', selectedFunnel || '');

    try {
      setLoading(true);
      const response = await api.post('/crm/leads/import_leads/', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      const { success_count, failure_count, errors } = response.data;
      if (failure_count > 0) {
        const normalizedErrors = Array.isArray(errors) ? errors : [errors];
        const failedRows = normalizedErrors.map((errorItem) => {
          const { row, errors: rowErrors } = errorItem;
          const dataRow = parsedData[row - 2];
          let errorArray = [];
          if (Array.isArray(rowErrors)) {
            errorArray = rowErrors;
          } else if (typeof rowErrors === 'object' && rowErrors !== null) {
            Object.keys(rowErrors).forEach((field) => {
              const fieldErrors = rowErrors[field];
              if (Array.isArray(fieldErrors)) {
                fieldErrors.forEach((msg) => errorArray.push(`${field}: ${msg}`));
              } else {
                errorArray.push(`${field}: ${fieldErrors}`);
              }
            });
          } else if (typeof rowErrors === 'string') {
            errorArray.push(rowErrors);
          }
          return { ...dataRow, row, errors: errorArray };
        });
        setImportErrors(normalizedErrors);
        setFailedRowsData(failedRows);
        setShowErrorModal(true);
        showSnackbar(
          `${success_count} leads imported successfully. ${failure_count} leads failed to import.`,
          failure_count > 0 ? 'warning' : 'success'
        );
      } else {
        showSnackbar('All leads imported successfully', 'success');
      }
      fetchLeads();
    } catch (error) {
      console.error('Error importing leads:', error);
      showSnackbar('Failed to import leads', 'error');
    } finally {
      setLoading(false);
      event.target.value = null;
    }
  };

  // More actions menus
  const handleMoreActionsClick = (e) => {
    setMoreActionsAnchorEl(e.currentTarget);
  };
  const handleMoreActionsClose = () => {
    setMoreActionsAnchorEl(null);
  };
  const handleBulkActionsClick = (e) => {
    setBulkActionsAnchorEl(e.currentTarget);
  };
  const handleBulkActionsClose = () => {
    setBulkActionsAnchorEl(null);
    setAssignFunnelAnchorEl(null);
  };
  const handleAssignFunnelClick = (e) => {
    setAssignFunnelAnchorEl(e.currentTarget);
  };
  const handleAssignFunnelClose = () => {
    setAssignFunnelAnchorEl(null);
  };
  const handleActionsMenuOpen = (e, lead) => {
    setActionsAnchorEl(e.currentTarget);
    setCurrentLead(lead);
  };
  const handleActionsMenuClose = () => {
    setActionsAnchorEl(null);
    setCurrentLead(null);
  };

  const handleDownloadTemplate = () => {
    if (!selectedCampaign) {
      showSnackbar('Please select a campaign to download the template', 'error');
      return;
    }
    api
      .get('/crm/leads/download_template/', {
        params: { campaign_id: selectedCampaign },
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'lead_import_template.csv');
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        console.error('Error downloading template:', error);
        showSnackbar('Failed to download template', 'error');
      });
  };

  // Build columns
  const getColumns = () => {
    const baseColumns = [
      { id: 'lead_details', label: 'Lead Details', minWidth: 50, sortable: false },
      { id: 'first_name', label: 'First Name', minWidth: 100, sortable: true },
      { id: 'last_name', label: 'Last Name', minWidth: 100, sortable: true },
      { id: 'email', label: 'Email', minWidth: 170, sortable: true },
      { id: 'phone_number', label: 'Phone Number', minWidth: 130, sortable: true },
      { id: 'current_step', label: 'Stage', minWidth: 100, sortable: true },
      { id: 'status', label: 'Status', minWidth: 100, sortable: true },
      { id: 'assigned_to', label: 'Assigned To', minWidth: 130, sortable: true },
    ];

    const subclassColumns = [];
    if (campaignDetails && campaignDetails.campaign_model) {
      const leadType = campaignDetails.campaign_model.name;
      if (leadType === 'B2B') {
        subclassColumns.push(
          { id: 'company', label: 'Company', minWidth: 100, sortable: true },
          { id: 'job_title', label: 'Job Title', minWidth: 100, sortable: true },
          { id: 'industry', label: 'Industry', minWidth: 100, sortable: true },
          { id: 'company_size', label: 'Company Size', minWidth: 100, sortable: true },
          { id: 'annual_revenue', label: 'Annual Revenue', minWidth: 100, sortable: true }
        );
      } else if (leadType === 'D2C') {
        subclassColumns.push(
          { id: 'date_of_birth', label: 'Date of Birth', minWidth: 100, sortable: true },
          { id: 'age', label: 'Age', minWidth: 50, sortable: true },
          { id: 'gender', label: 'Gender', minWidth: 50, sortable: true },
          { id: 'marital_status', label: 'Marital Status', minWidth: 100, sortable: true }
        );
      }
    }

    const dynamicFieldColumns = (dynamicFields || []).map((field) => ({
      id: `dynamic_${field.api_name}`,
      label: field.name,
      minWidth: 100,
      sortable: true,
    }));
    const endColumns = [{ id: 'actions', label: 'Actions', minWidth: 50, sortable: true }];

    return [...baseColumns, ...subclassColumns, ...dynamicFieldColumns, ...endColumns];
  };

  const columns = useMemo(() => getColumns(), [campaignDetails, dynamicFields]);

  // Function to download CSV of failed rows
  const downloadFailedRowsCSV = () => {
    if (failedRowsData.length === 0) return;
    const headers = [
      ...Object.keys(failedRowsData[0]).filter((key) => key !== 'errors'),
      'Errors',
    ];
    const dataWithErrors = failedRowsData.map((row) => {
      const { row: rowNumber, errors, ...rest } = row;
      return { ...rest, Errors: errors.join('; ') };
    });
    const csv = Papa.unparse({ fields: headers, data: dataWithErrors });
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    const timestamp = new Date().toISOString().replace(/[-:]/g, '').replace('T', '_').slice(0, 15);
    link.setAttribute('download', `failed_leads_${timestamp}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSelectionModelChange = (newSelectionModel) => {
    // console.log('Selection model changed:', newSelectionModel);
    setRowSelectionModel(newSelectionModel);
  };

  const handleDeleteLead = useCallback((leadId) => {
    setDeleteDialog({
      open: true,
      leadId,
      isLoading: false
    });
  }, []);

  const handleConfirmDelete = async () => {
    try {
      setDeleteDialog(prev => ({ ...prev, isLoading: true }));
      
      await api.delete(`/crm/leads/${deleteDialog.leadId}/`);
      
      // Update local state to remove the deleted lead
      setLeads(currentLeads => 
        currentLeads.filter(lead => lead.id !== deleteDialog.leadId)
      );
      
      // Update total count
      setTotalLeads(prev => prev - 1);
      
      showSnackbar('Lead deleted successfully', 'success');
      setDeleteDialog({ open: false, leadId: null, isLoading: false });
    } catch (error) {
      console.error('Error deleting lead:', error);
      showSnackbar('Failed to delete lead', 'error');
      setDeleteDialog(prev => ({ ...prev, isLoading: false }));
    }
  };

  const handleCancelDelete = () => {
    setDeleteDialog({ open: false, leadId: null, isLoading: false });
  };

  // Add function to handle finishing new lead creation
  const handleFinishNewLead = async () => {
    if (!newLeadData) return;

    try {
      const payload = {
        ...newLeadData,
        campaign: selectedCampaign,
        funnel: selectedFunnel,
        last_updated_by: currentUser.id,
        lead_type: campaignDetails ? campaignDetails.campaign_model.name.toLowerCase() : '',
      };

      // Clean up the payload
      delete payload.id;
      delete payload.isNew;
      delete payload.field_name;
      delete payload.field_api_name;
      delete payload.current_step_name;

      // Send the complete new lead data
      await api.post('/crm/leads/', payload);
      showSnackbar('New lead added successfully', 'success');
      
      // Reset states
      setNewLeadData(null);
      setEditingCell(null);
      fetchLeads();
    } catch (error) {
      console.error('Error creating new lead:', error);
      showSnackbar('Failed to create lead', 'error');
    }
  };

  return (
    <Box sx={{ width: '100%', backgroundColor: theme.palette.background.default, p: 2 }}>
      {/* Account / Campaign / Funnel Selection */}
      <Grid container spacing={2} sx={{ mb: 2 }}>
        {/* Account Selection */}
        <Grid item xs={12} sm={6} md={4}>
          {accountsLoading ? (
            <CircularProgress />
          ) : (
            <FormControl fullWidth>
              <InputLabel>Select Account</InputLabel>
              <Select
                value={selectedAccount}
                onChange={handleAccountChange}
                label="Select Account"
              >
                {accounts.map((acc) => (
                  <MenuItem key={acc.id} value={String(acc.id)}>
                    {acc.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>

        {/* Campaign Selection */}
        {selectedAccount && !accountsLoading && (
          <Grid item xs={12} sm={6} md={4}>
            {campaignsLoading ? (
              <CircularProgress />
            ) : (
              <FormControl fullWidth>
                <InputLabel>Select Campaign</InputLabel>
                <Select
                  value={selectedCampaign}
                  onChange={handleCampaignChange}
                  label="Select Campaign"
                >
                  {campaigns.map((campaign) => (
                    <MenuItem key={campaign.id} value={String(campaign.id)}>
                      {campaign.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
        )}

        {/* Funnel Selection */}
        {selectedAccount && !accountsLoading && selectedCampaign && !campaignsLoading && (
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <InputLabel>Select Funnel (Optional)</InputLabel>
              <Select
                value={selectedFunnel}
                onChange={handleFunnelChange}
                label="Select Funnel"
              >
                <MenuItem value="">All Funnels</MenuItem>
                {funnels.map((fun) => (
                  <MenuItem key={fun.id} value={String(fun.id)}>
                    {fun.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>

      {/* Action Buttons */}
      <Stack direction="row" spacing={2} sx={{ mb: 2, alignItems: 'center' }}>
        <Stack direction="row" spacing={1}>
          <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddRow}>
            Add Lead
          </Button>
          <Button
            variant="contained"
            onClick={handleBulkActionsClick}
            endIcon={<MoreVertIcon />}
            disabled={rowSelectionModel.length === 0}
          >
            Bulk Actions ({rowSelectionModel.length})
          </Button>
          <BulkActionsMenu
            anchorEl={bulkActionsAnchorEl}
            open={Boolean(bulkActionsAnchorEl)}
            onClose={handleBulkActionsClose}
            funnels={funnels}
            handleBulkDelete={handleBulkDelete}
            handleAssignFunnelClick={handleAssignFunnelClick}
            bulkActionLoading={bulkActionLoading}
          />
          <Menu
            anchorEl={assignFunnelAnchorEl}
            open={Boolean(assignFunnelAnchorEl)}
            onClose={handleAssignFunnelClose}
          >
            {funnels.map((f) => (
              <MenuItem
                key={f.id}
                onClick={() => {
                  handleBulkAction('assign', f.id);
                  handleAssignFunnelClose();
                  handleBulkActionsClose();
                }}
                disabled={bulkActionLoading}
              >
                {bulkActionLoading ? <CircularProgress size={20} /> : f.name}
              </MenuItem>
            ))}
          </Menu>

          <Button variant="contained" onClick={handleMoreActionsClick} endIcon={<MoreVertIcon />}>
            More Actions
          </Button>
          <Menu
            anchorEl={moreActionsAnchorEl}
            open={Boolean(moreActionsAnchorEl)}
            onClose={handleMoreActionsClose}
          >
            <MenuItem onClick={handleExport} disabled={loading || !selectedCampaign}>
              <ListItemIcon>
                <FileDownloadIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Export Leads</ListItemText>
            </MenuItem>
            <MenuItem onClick={triggerFileInput} disabled={loading || !selectedCampaign}>
              <ListItemIcon>
                <FileUploadIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Import Leads</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleDownloadTemplate} disabled={!selectedCampaign}>
              <ListItemIcon>
                <FileDownloadIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Download Template</ListItemText>
            </MenuItem>
          </Menu>
        </Stack>

        {/* Hidden file input for import */}
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleImport}
          accept=".csv"
        />
      </Stack>

      {/* Leads Table */}
      {selectedCampaign && (
        <LeadsTable
          leads={leads}
          columns={columns}
          loading={loading}
          orderBy={orderBy}
          order={order}
          handleRequestSort={handleRequestSort}
          handleFilterChange={handleFilterChange}
          filterModel={filterModel}
          handleCellChange={handleCellChange}
          handleCellClick={handleCellClick}
          editingCell={editingCell}
          navigate={navigate}
          handleActionsMenuOpen={handleActionsMenuOpen}
          selectedLeads={selectedLeads}
          onSelectionChange={handleLeadSelect}
          phoneNumberRegex={phoneNumberRegex}
          emailRegex={emailRegex}
          leadStatuses={leadStatuses}
          campaignMembers={campaignMembers}
          funnelSteps={funnelSteps}
          dynamicFields={dynamicFields}
          page={page}
          rowsPerPage={rowsPerPage}
          totalLeads={totalLeads}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          rowSelectionModel={rowSelectionModel}
          onRowSelectionModelChange={handleSelectionModelChange}
          handleDeleteLead={handleDeleteLead}
          newLeadData={newLeadData}
          onSaveNewLead={handleFinishNewLead}
          onCancelNewLead={handleCancel}
        />
      )}

      {/* Bulk Delete Confirmation */}
      <Dialog
        open={bulkDeleteConfirmationOpen}
        onClose={() => setBulkDeleteConfirmationOpen(false)}
      >
        <DialogTitle>Confirm Bulk Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {rowSelectionModel.length} lead(s)? This action
            cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setBulkDeleteConfirmationOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmBulkDelete} color="secondary" disabled={bulkActionLoading}>
            {bulkActionLoading ? <CircularProgress size={20} /> : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirm Save Lead Dialog */}
      <Dialog open={openConfirmation} onClose={handleCancelSave}>
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The following issues were found:
            <ul>
              {warningMessage.map((msg, i) => (
                <li key={i}>{msg}</li>
              ))}
            </ul>
            Are you sure you want to save the lead with these values?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelSave} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmSave} color="secondary">
            Proceed and Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Error Modal for Import Errors */}
      <ErrorModal
        open={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        failedRowsData={failedRowsData}
        downloadFailedRowsCSV={downloadFailedRowsCSV}
      />

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <ConfirmDialog
        open={deleteDialog.open}
        title="Delete Lead"
        message="Are you sure you want to delete this lead? This action cannot be undone."
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
        isLoading={deleteDialog.isLoading}
        confirmText="Delete"
        confirmColor="error"
      />
    </Box>
  );
};

export default LeadsManagement;
