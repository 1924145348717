import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Typography,
  Paper,
  Chip
} from '@mui/material';
import { Edit, Delete, Schedule } from '@mui/icons-material';
import { format, utcToZonedTime } from 'date-fns-tz';

const ScheduledReachOutsView = ({
  scheduledReachOuts,
  onCreateCallback,
  onEditCallback,
  onDeleteCallback,
  userTimezone,
  theme
}) => {
  const formatDateTime = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    const zonedDate = utcToZonedTime(date, userTimezone || 'UTC');
    return format(zonedDate, 'MMM dd, yyyy HH:mm', { timeZone: userTimezone || 'UTC' });
  };

  const toTitleCase = (str) => {
    if (!str) return '';
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  };

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'completed':
        return 'success';
      case 'pending':
        return 'warning';
      case 'cancelled':
        return 'error';
      default:
        return 'default';
    }
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6" component="h2" color="textPrimary">
          Scheduled Follow-ups
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Schedule />}
          onClick={() => onCreateCallback()}
        >
          Schedule New Follow-up
        </Button>
      </Box>

      {scheduledReachOuts.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Lead</TableCell>
                <TableCell>Scheduled Time</TableCell>
                <TableCell>Reason</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Notes</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {scheduledReachOuts.map((reachOut) => (
                <TableRow key={reachOut.id}>
                  <TableCell>
                    <Link
                      to={`/crm/leads/${reachOut.lead}/details`}
                      style={{
                        color: theme.palette.primary.main,
                        textDecoration: 'none',
                        fontWeight: 500,
                      }}
                    >
                      {reachOut.lead_name || 'N/A'}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center" gap={1}>
                      <Schedule fontSize="small" color="action" />
                      {formatDateTime(reachOut.scheduled_date)}
                    </Box>
                  </TableCell>
                  <TableCell>{reachOut.reason}</TableCell>
                  <TableCell>{reachOut.description}</TableCell>
                  <TableCell>{reachOut.notes}</TableCell>
                  <TableCell>
                    <Chip
                      label={toTitleCase(reachOut.status)}
                      size="small"
                      color={getStatusColor(reachOut.status)}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => onEditCallback(reachOut)}
                      color="primary"
                      title="Edit"
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => onDeleteCallback(reachOut.id)}
                      color="error"
                      title="Delete"
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Paper sx={{ p: 3, textAlign: 'center' }}>
          <Typography variant="body1" color="textSecondary">
            No scheduled follow-ups found
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<Schedule />}
            onClick={() => onCreateCallback()}
            sx={{ mt: 2 }}
          >
            Schedule Your First Follow-up
          </Button>
        </Paper>
      )}
    </Box>
  );
};

ScheduledReachOutsView.propTypes = {
  scheduledReachOuts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    lead: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    lead_name: PropTypes.string,
    scheduled_date: PropTypes.string,
    reason: PropTypes.string,
    description: PropTypes.string,
    notes: PropTypes.string,
    status: PropTypes.string
  })).isRequired,
  onCreateCallback: PropTypes.func.isRequired,
  onEditCallback: PropTypes.func.isRequired,
  onDeleteCallback: PropTypes.func.isRequired,
  userTimezone: PropTypes.string,
  theme: PropTypes.object.isRequired
};

export default ScheduledReachOutsView;