// src/components/AgentConfiguration.jsx
import React from 'react';
import { useForm } from 'react-hook-form';
import {
  Box, Card, CardContent, TextField, FormControl,
  InputLabel, Select, MenuItem, Switch, Button,
  FormControlLabel, Typography
} from '@mui/material';
import { api } from '../services/authService';
import { AGENT_TYPES, AGENT_DIRECTIONS } from '../types/agent';

const AgentConfiguration = ({ campaign, onSave, onCancel }) => {
  const { register, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      campaign: campaign.id,
      direction: AGENT_DIRECTIONS.OUTBOUND,
      event_triggered: false,
      position: 0
    }
  });

  const selectedType = watch('type');

  const renderTypeSpecificFields = () => {
    switch (selectedType) {
      case AGENT_TYPES.VOICE:
        return (
          <>
            <TextField
              fullWidth
              label="Bland AI Pathway"
              {...register('bland_ai_pathway')}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Voice Script"
              multiline
              rows={4}
              {...register('voice_script')}
              margin="normal"
            />
          </>
        );
      case AGENT_TYPES.CHAT:
        return (
          <>
            <TextField
              fullWidth
              label="Twilio Conversation ID"
              {...register('twilio_conversation_id')}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Chat Script"
              multiline
              rows={4}
              {...register('chat_script')}
              margin="normal"
            />
          </>
        );
      case AGENT_TYPES.EMAIL:
        return (
          <>
            <TextField
              fullWidth
              label="Mailchimp Campaign ID"
              {...register('mailchimp_campaign_id')}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Email Script"
              multiline
              rows={4}
              {...register('email_script')}
              margin="normal"
            />
          </>
        );
      default:
        return null;
    }
  };

  const onSubmit = async (data) => {
    try {
      await onSave(data);
    } catch (error) {
      console.error('Error saving agent:', error);
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Configure Agent
        </Typography>
        
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            fullWidth
            label="Agent Name"
            {...register('name')}
            margin="normal"
          />

          <FormControl fullWidth margin="normal">
            <InputLabel>Agent Type</InputLabel>
            <Select
              value={selectedType || ''}
              onChange={(e) => setValue('type', e.target.value)}
              label="Agent Type"
            >
              {Object.entries(AGENT_TYPES).map(([key, value]) => (
                <MenuItem key={key} value={value}>
                  {key}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Funnel</InputLabel>
            <Select
              {...register('funnel')}
              label="Funnel"
            >
              {campaign.funnels.map((funnel) => (
                <MenuItem key={funnel.id} value={funnel.id}>
                  {funnel.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            fullWidth
            type="number"
            label="Position"
            {...register('position')}
            margin="normal"
          />

          <FormControl fullWidth margin="normal">
            <InputLabel>Direction</InputLabel>
            <Select
              {...register('direction')}
              label="Direction"
            >
              {Object.entries(AGENT_DIRECTIONS).map(([key, value]) => (
                <MenuItem key={value} value={value}>
                  {key}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControlLabel
            control={
              <Switch {...register('event_triggered')} />
            }
            label="Event Triggered"
            margin="normal"
          />

          <TextField
            fullWidth
            label="Schedule Interval"
            {...register('schedule_interval')}
            margin="normal"
          />

          <TextField
            fullWidth
            type="time"
            label="Schedule Time"
            {...register('schedule_time')}
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />

          {renderTypeSpecificFields()}

          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button onClick={onCancel} variant="outlined">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Save Agent
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
};

export default AgentConfiguration;