// src/components/LeadsTable.js

import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { 
    DataGrid, 
    GridToolbar,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarQuickFilter,
} from '@mui/x-data-grid'; 
/** 
 * IMPORTANT: We use @mui/x-data-grid (the free version).
 * Row editing & pinned columns are Pro/Premium features and are not included here.
 */
import {
  CircularProgress,
  Box,
  Select,
  MenuItem,
  TextField,
  IconButton,
  Menu,
  ListItemIcon,
  ListItemText,
  Button,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Search as SearchIcon, MoreVert as MoreVertIcon, Delete as DeleteIcon } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import './LeadsTable.css'; // Import the CSS file for styling
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';

// Update CustomToolbar to accept props
function CustomToolbar({ newLeadData, onSaveNewLead, onCancelNewLead }) {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <Box sx={{ flexGrow: 1 }} />
      {newLeadData && (
        <>
          <Button
            startIcon={<SaveIcon />}
            variant="contained"
            color="primary"
            onClick={onSaveNewLead}
            sx={{ mr: 1 }}
          >
            Save New Lead
          </Button>
          <Button
            startIcon={<CancelIcon />}
            variant="outlined"
            color="secondary"
            onClick={onCancelNewLead}
            sx={{ mr: 2 }}
          >
            Cancel
          </Button>
        </>
      )}
      <GridToolbarQuickFilter
        quickFilterProps={{ debounceMs: 500 }}
      />
    </GridToolbarContainer>
  );
}

const LeadsTable = ({
  leads,
  columns,
  loading,
  orderBy,
  order,
  handleRequestSort,
  handleCellClick,
  handleCellChange,
  editingCell,
  navigate,
  phoneNumberRegex,
  emailRegex,
  leadStatuses,
  campaignMembers,
  funnelSteps,
  dynamicFields,
  page,
  rowsPerPage,
  totalLeads,
  setPage,
  setRowsPerPage,
  handleFilterChange,
  filterModel,
  rowSelectionModel,
  onRowSelectionModelChange,
  handleDeleteLead,
  newLeadData,
  onSaveNewLead,
  onCancelNewLead,
}) => {
  const theme = useTheme();

  /**
   * We keep the widths from your columns,
   * but note that the Data Grid also supports automatic flex sizing.
   */
  const [columnWidths] = useState(
    columns.reduce((acc, column) => {
      acc[column.id] = column.minWidth || 120; // default minWidth
      return acc;
    }, {})
  );

  // Add state for menu control
  const [menuState, setMenuState] = useState({
    anchorEl: null,
    currentLead: null
  });

  // Add handlers for menu
  const handleActionsMenuOpen = (event, lead) => {
    event.stopPropagation(); // Prevent event bubbling
    setMenuState({
      anchorEl: event.currentTarget,
      currentLead: lead
    });
  };

  const handleActionsMenuClose = () => {
    setMenuState({
      anchorEl: null,
      currentLead: null
    });
  };

  /**
   * The following function reuses your custom logic
   * for displaying or editing cells. Since the free Data Grid
   * doesn't provide row/cell editing, we keep your manual approach.
   */
  const renderCustomCell = (params, column) => {
    const lead = params.row;
    const isEditing =
      editingCell &&
      editingCell.leadId === lead.id &&
      editingCell.columnId === column.id;

    // 1. Dynamic fields
    if (column.id.startsWith('dynamic_')) {
      const apiName = column.id.replace('dynamic_', '');
      const fieldDefinition = dynamicFields.find(
        (field) => field.api_name === apiName
      );
      if (!fieldDefinition) {
        console.error(`Field definition not found for api_name: ${apiName}`);
        return <div style={{ color: 'red' }}>Field definition not found</div>;
      }

      const fieldValue = (lead.field_values || []).find(
        (value) => value.field_api_name === apiName
      );
      const value = fieldValue ? fieldValue.value : '';

      const handleValueChange = (e) => {
        handleCellChange(lead.id, { [column.id]: e.target.value }, fieldDefinition.id);
      };

      if (isEditing || lead.id === 'new') {
        if (fieldDefinition.field_type === 'dropdown' && fieldDefinition.dropdown_options) {
          return (
            <Select value={value || ''} onChange={handleValueChange} fullWidth>
              {fieldDefinition.dropdown_options.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          );
        } else if (fieldDefinition.field_type === 'number') {
          return (
            <TextField
              value={value || ''}
              onChange={handleValueChange}
              type="number"
              fullWidth
            />
          );
        } else if (fieldDefinition.field_type === 'date') {
          const dateValue = value ? moment(value) : null;
          return (
            <DatePicker
              value={dateValue}
              onChange={(newValue) => {
                const momentValue = moment.isMoment(newValue) ? newValue : moment(newValue);
                if (momentValue && momentValue.isValid()) {
                  handleCellChange(
                    lead.id, 
                    { [column.id]: momentValue.format('YYYY-MM-DD') },
                    fieldDefinition.id
                  );
                }
              }}
              slotProps={{
                textField: {
                  size: "small",
                  fullWidth: true,
                  error: false,
                  helperText: null
                },
                popper: {
                  placement: 'bottom-start'
                }
              }}
            />
          );
        } else if (fieldDefinition.field_type === 'boolean') {
          return (
            <Select value={value || ''} onChange={handleValueChange} fullWidth>
              <MenuItem value>True</MenuItem>
              <MenuItem value={false}>False</MenuItem>
            </Select>
          );
        }
        // Default text handling
        return (
          <TextField
            value={value || ''}
            onChange={handleValueChange}
            fullWidth
          />
        );
      }

      // Read mode
      return (
        <div onClick={() => handleCellClick(lead.id, column.id)}>
          {value || '—'}
        </div>
      );
    }

    // 2. Status
    if (column.id === 'status') {
      const statusValue = lead.status?.id || lead.status || '';
      if (isEditing || lead.id === 'new') {
        return (
          <Select
            value={statusValue}
            onChange={(e) => handleCellChange(lead.id, { status: e.target.value })}
            fullWidth
          >
            {leadStatuses.map((s) => (
              <MenuItem key={s.id} value={s.id}>
                {s.name}
              </MenuItem>
            ))}
          </Select>
        );
      }
      const statusName =
        lead.status_name ||
        leadStatuses.find((s) => s.id === (lead.status?.id || lead.status))?.name ||
        'No Status';
      return (
        <div onClick={() => handleCellClick(lead.id, column.id)}>
          {statusName}
        </div>
      );
    }

    // 3. Assigned to
    if (column.id === 'assigned_to') {
      const assignedToId = lead.assigned_to?.id || lead.assigned_to;
      if (isEditing || lead.id === 'new') {
        return (
          <Select
            value={assignedToId || ''}
            onChange={(e) => handleCellChange(lead.id, { assigned_to: e.target.value })}
            fullWidth
          >
            {campaignMembers.map((member) => (
              <MenuItem key={member.user.id} value={member.user.id}>
                {`${member.user.first_name} ${member.user.last_name}`}
              </MenuItem>
            ))}
          </Select>
        );
      }
      const member = campaignMembers.find((m) => m.user.id === assignedToId)?.user;
      return (
        <div onClick={() => handleCellClick(lead.id, column.id)}>
          {member
            ? `${member.first_name} ${member.last_name}`
            : 'Unassigned'}
        </div>
      );
    }

    // 4. Lead Details link
    if (column.id === 'lead_details') {
      return (
        <IconButton onClick={() => navigate(`/crm/leads/${lead.id}/details`)}>
          <SearchIcon />
        </IconButton>
      );
    }

    // 5. Actions
    if (column.id === 'actions') {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <IconButton 
            onClick={(e) => handleActionsMenuOpen(e, lead)}
            size="small"
            title="More actions"
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={menuState.anchorEl}
            open={Boolean(menuState.anchorEl) && menuState.currentLead?.id === lead.id}
            onClose={handleActionsMenuClose}
          >
            <MenuItem onClick={() => {
              handleActionsMenuClose();
              navigate(`/crm/leads/${lead.id}/details`);
            }}>
              <ListItemIcon>
                <SearchIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>View Details</ListItemText>
            </MenuItem>
            <MenuItem 
              onClick={() => {
                handleActionsMenuClose();
                handleDeleteLead(lead.id);
              }}
              sx={{ color: 'error.main' }}
            >
              <ListItemIcon>
                <DeleteIcon fontSize="small" color="error" />
              </ListItemIcon>
              <ListItemText>Delete Lead</ListItemText>
            </MenuItem>
          </Menu>
        </Box>
      );
    }

    // 6. Gender
    if (column.id === 'gender') {
      const genderOptions = [
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' },
        { value: 'other', label: 'Other' },
      ];
      if (isEditing || lead.id === 'new') {
        return (
          <Select
            value={(lead.gender || '').toLowerCase()}
            onChange={(e) =>
              handleCellChange(lead.id, { gender: e.target.value.toLowerCase() })
            }
            fullWidth
          >
            {genderOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        );
      }
    }

    // 7. Date of birth / age logic
    if (column.id === 'date_of_birth' || column.id.includes('date')) {
      const dateValue = lead[column.id] ? moment(lead[column.id]) : null;

      if (isEditing || lead.id === 'new') {
        return (
          <DatePicker
            value={dateValue}
            onChange={(newValue) => {
              const momentValue = moment.isMoment(newValue) ? newValue : moment(newValue);
              if (momentValue && momentValue.isValid()) {
                const formattedDate = momentValue.format('YYYY-MM-DD');
                if (column.id === 'date_of_birth') {
                  // Calculate age
                  const birthDate = momentValue.toDate();
                  const today = new Date();
                  let age = today.getFullYear() - birthDate.getFullYear();
                  const m = today.getMonth() - birthDate.getMonth();
                  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                    age--;
                  }
                  handleCellChange(lead.id, { date_of_birth: formattedDate, age });
                } else {
                  handleCellChange(lead.id, { [column.id]: formattedDate });
                }
              }
            }}
            closeOnSelect={false}
            disableOpenPicker={false}
            showDaysOutsideCurrentMonth
            reduceAnimations={false}
            slotProps={{
              textField: {
                size: "small",
                fullWidth: true,
                error: false,
                helperText: null
              },
              popper: {
                placement: 'bottom-start'
              }
            }}
          />
        );
      }
      
      // Read-only view for date fields
      return (
        <div onClick={() => handleCellClick(lead.id, column.id)}>
          {dateValue && dateValue.isValid() ? dateValue.format('YYYY-MM-DD') : '—'}
        </div>
      );
    }

    // 8. Phone number validation
    if (column.id === 'phone_number') {
      const isValidPhone = phoneNumberRegex.test(lead.phone_number || '');
      return isEditing || lead.id === 'new' ? (
        <TextField
          value={lead.phone_number || ''}
          onChange={(e) => handleCellChange(lead.id, { phone_number: e.target.value })}
          error={!isValidPhone && lead.phone_number !== ''}
          helperText={
            !isValidPhone && lead.phone_number !== '' ? 'Format: XXX-XXX-XXXX' : ''
          }
          placeholder="XXX-XXX-XXXX"
          fullWidth
        />
      ) : (
        <div onClick={() => handleCellClick(lead.id, column.id)}>
          {lead.phone_number || '—'}
        </div>
      );
    }

    // 9. Email validation
    if (column.id === 'email') {
      const isValidEmail = emailRegex.test(lead.email || '');
      return isEditing || lead.id === 'new' ? (
        <TextField
          value={lead.email || ''}
          onChange={(e) => handleCellChange(lead.id, { email: e.target.value })}
          error={!isValidEmail && lead.email !== ''}
          helperText={
            !isValidEmail && lead.email !== '' ? 'Invalid email address' : ''
          }
          placeholder="example@domain.com"
          fullWidth
        />
      ) : (
        <div onClick={() => handleCellClick(lead.id, column.id)}>
          {lead.email || '—'}
        </div>
      );
    }

    // 10. current_step
    if (isEditing || lead.id === 'new') {
      if (column.id === 'current_step') {
        const currentStepValue = lead.current_step?.id || lead.current_step || '';
        return (
          <Select
            value={currentStepValue}
            onChange={(e) => handleCellChange(lead.id, { current_step: e.target.value })}
            fullWidth
          >
            {funnelSteps.map((step) => (
              <MenuItem key={step.id} value={step.id}>
                {step.name}
              </MenuItem>
            ))}
          </Select>
        );
      } else if (column.id === 'date_of_birth' || column.id === 'age') {
        return (
          <TextField
            value={lead[column.id] || ''}
            onChange={(e) => handleCellChange(lead.id, { age: e.target.value, date_of_birth: null })}
            type={column.id === 'date_of_birth' ? 'date' : 'number'}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        );
      }
      // Fallback: generic text
      return (
        <TextField
          value={lead[column.id] || ''}
          onChange={(e) => handleCellChange(lead.id, { [column.id]: e.target.value })}
          type={column.type || 'text'}
          fullWidth
          autoFocus
        />
      );
    }

    // 11. Read-only current_step
    if (column.id === 'current_step') {
      const stepName =
        lead.current_step_name ||
        funnelSteps.find((s) => s.id === (lead.current_step?.id || lead.current_step))
          ?.name ||
        'N/A';
      return (
        <div onClick={() => handleCellClick(lead.id, column.id)}>
          {stepName}
        </div>
      );
    }

    // 12. Default (read-only)
    return (
      <div onClick={() => handleCellClick(lead.id, column.id)}>
        {lead[column.id] || '—'}
      </div>
    );
  };

  /**
   * Convert your columns array into Data Grid columns with custom renderCell.
   */
  const dataGridColumns = columns.map((col) => ({
    field: col.id,
    headerName: col.label,
    sortable: col.sortable,
    width: columnWidths[col.id], // or use flex for auto sizing
    renderCell: (params) => renderCustomCell(params, col),
    // Remove pinned columns (not supported in the MIT version)
  }));

  /**
   * Let Data Grid handle sorting visually, but call `handleRequestSort` 
   * if you need to store that state or do server-side sorting.
   */
  const handleSortModelChange = (sortModel) => {
    if (sortModel.length) {
      const { field, sort } = sortModel[0];
      handleRequestSort(field, sort);
    } else {
      // Clear sort
      handleRequestSort('', 'asc');
    }
  };

  // Process columns to add editing capabilities
  const processedColumns = useMemo(() => 
    dataGridColumns.map(column => ({
      ...column,
      editable: column.field !== 'actions' && 
                column.field !== 'lead_details' &&
                column.field !== 'id',
    })),
  [dataGridColumns]);

  // Handle saving updates
  const handleProcessRowUpdate = useCallback(
    async (newRow, oldRow) => {
      try {
        const updates = {};
        const fieldValues = [];
  
        Object.keys(newRow).forEach((key) => {
          const newValue = newRow[key];
  
          if (key.startsWith('dynamic_')) {
            console.log('key:', key);
            const apiName = key.replace('dynamic_', '');
  
            // Find the matching field definition by api_name
            const fieldDefinition = dynamicFields.find(
              (field) => field.api_name === apiName
            );
            if (!fieldDefinition) {
              console.error(`Field definition not found for apiName: ${apiName}`);
              return;
            }

            // console.log('fieldDefinition:', fieldDefinition);
            // console.log('newValue:', newValue);
  
            // Build the item for field_values array
            fieldValues.push({
              field_definition: fieldDefinition.id, // NOT field_definition_id
              field_api_name: apiName,
              value: newValue,
            });
          }
          // Otherwise, check if it's a changed normal field
          else if (newRow[key] !== oldRow[key]) {
            updates[key] = newValue;
          }
        });
  
        // If any dynamic fields were changed, stick them into updates
        if (fieldValues.length > 0) {
          updates.field_values = fieldValues;
        }
  
        // Only send update if we have something
        if (Object.keys(updates).length > 0) {
          // console.log('Sending update payload:', updates);
          await handleCellChange(newRow.id, updates);
        }
  
        return newRow; // Let DataGrid finalize the row changes
      } catch (error) {
        console.error('Error updating lead:', error);
        throw new Error('Failed to update lead');
      }
    },
    [dynamicFields, handleCellChange]
  );  

  return (
    <Box sx={{ width: '100%', height: '75vh' }}>
      {loading ? (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <DataGrid
          rows={leads}
          columns={processedColumns}
          getRowId={(row) => row.id}
          loading={loading}
          initialState={{
            filter: {
              filterModel: filterModel,
            },
          }}
          
          filterMode="server"
          onFilterModelChange={(newFilterModel) => {
            // console.log('New filter model:', newFilterModel);
            handleFilterChange(newFilterModel);
          }}

          // Sorting
          sortingOrder={['asc', 'desc']}
          sortModel={
            orderBy ? [{ field: orderBy, sort: order }] : []
          }
          onSortModelChange={handleSortModelChange}

          // Checkbox selection
          checkboxSelection
          disableSelectionOnClick
          disableRowSelectionOnClick
          keepNonExistentRowsSelected
          rowSelectionModel={rowSelectionModel}
          onRowSelectionModelChange={onRowSelectionModelChange}

          // Let the user reorder & resize columns
          disableColumnReorder={false}
          disableColumnResize={false}
          
          // Pagination
          pagination
          paginationMode="server"
          paginationModel={{ page, pageSize: rowsPerPage }}
          onPaginationModelChange={(newPaginationModel) => {
            setPage(newPaginationModel.page);
            setRowsPerPage(newPaginationModel.pageSize);            
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          rowCount={totalLeads}
          rowsPerPage={rowsPerPage}
          totalLeads={totalLeads}
          
          // Use the new "slots" prop to render the toolbar
          slots={{
            toolbar: CustomToolbar
          }}
          slotProps={{
            pagination: {
              showFirstButton: true,
              showLastButton: true,
            },
            toolbar: {
              newLeadData,
              onSaveNewLead,
              onCancelNewLead,
              showQuickFilter: true,
              quickFilterProps: {
                debounceMs: 500,
              },
            },
          }}

          sx={{
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            // Example row-level styling
            '& .my-custom-class': {
              // custom CSS if you wanted row highlights, etc.
            },
          }}

          // Editing props
          editMode="cell"
          processRowUpdate={handleProcessRowUpdate}
          onProcessRowUpdateError={(error) => {
            console.error('Error updating lead:', error);
          }}
          experimentalFeatures={{ newEditingApi: true }}
          editCellProps={{
            debounceMs: 500 // 500ms debounce
          }}
        />
      )}
    </Box>
  );
};

LeadsTable.propTypes = {
  leads: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  orderBy: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
  handleRequestSort: PropTypes.func.isRequired,
  handleCellClick: PropTypes.func.isRequired,
  handleCellChange: PropTypes.func.isRequired,
  editingCell: PropTypes.shape({
    leadId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    columnId: PropTypes.string,
  }),
  
  navigate: PropTypes.func.isRequired,
  handleActionsMenuOpen: PropTypes.func.isRequired,
  selectedLeads: PropTypes.array.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  phoneNumberRegex: PropTypes.object.isRequired,
  emailRegex: PropTypes.object.isRequired,
  leadStatuses: PropTypes.array.isRequired,
  campaignMembers: PropTypes.array.isRequired,
  funnelSteps: PropTypes.array.isRequired,
  dynamicFields: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  totalLeads: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  filterModel: PropTypes.shape({
    items: PropTypes.array,
    quickFilterValues: PropTypes.array,
  }),
  rowSelectionModel: PropTypes.array.isRequired,
  onRowSelectionModelChange: PropTypes.func.isRequired,
  handleDeleteLead: PropTypes.func.isRequired,
  newLeadData: PropTypes.shape({
    // Add appropriate prop types for newLeadData
  }),
  onSaveNewLead: PropTypes.func.isRequired,
  onCancelNewLead: PropTypes.func.isRequired,
};

export default LeadsTable;
