import React, { useState, useEffect } from 'react';
import {
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Tabs,
  Tab,
  Box,
  Divider,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Chip
} from '@mui/material';
import { Delete as DeleteIcon, Add as AddIcon, ArrowBack as ArrowBackIcon, Edit as EditIcon, Visibility, TrendingUp, Assessment, CheckCircle, Block } from '@mui/icons-material';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { api } from '../services/authService';
import { useTheme } from '@mui/material/styles';

const FunnelSettings = () => {
  const theme = useTheme(); // Access the theme for consistent styling
  const { funnelId } = useParams(); // Use funnel ID from the URL
  const navigate = useNavigate();
  const location = useLocation(); // Access location state  

  // Account, campaigns, and funnel from local storage or passed through navigation state
  const [account, setAccount] = useState(location.state?.accountId || null);
  const [selectedCampaign, setSelectedCampaign] = useState(location.state?.campaignId || '');
  const [funnel, setFunnel] = useState(null); // Funnel details object
  const [funnelSteps, setFunnelSteps] = useState([]);
  const [newStep, setNewStep] = useState({ 
    name: '', 
    order: 0, 
    description: '',
    step_type: 'awareness' // default value
  });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [stepDialogOpen, setStepDialogOpen] = useState(false);
  const [editingStep, setEditingStep] = useState(null);
  const [tabValue, setTabValue] = useState(0);  
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);  // Add this state for delete confirmation dialog
  const [confirmDeleteStepDialogOpen, setConfirmDeleteStepDialogOpen] = useState(false); // Delete step dialog state
  const [stepToDelete, setStepToDelete] = useState(null); // Store the step to delete

  const [isDefault, setIsDefault] = useState(false);
  const [defaultWarningDialog, setDefaultWarningDialog] = useState(false);

  // Add step type options constant
  const STEP_TYPE_OPTIONS = [
    { value: 'awareness', label: 'Awareness', icon: <Visibility />, color: 'info' },
    { value: 'interest', label: 'Interest', icon: <TrendingUp />, color: 'primary' },
    { value: 'decision', label: 'Decision', icon: <Assessment />, color: 'warning' },
    { value: 'conversion', label: 'Conversion', icon: <CheckCircle />, color: 'success' },
    { value: 'lost', label: 'Lost', icon: <Block />, color: 'error' }
  ];

  useEffect(() => {
    if (funnelId && selectedCampaign && account) {
      fetchFunnelDetails(funnelId); // Fetch campaign details using the campaign_id from the URL
    }
  }, [funnelId, selectedCampaign, account]);

  // Fetch funnel details function
  const fetchFunnelDetails = async (funnelId) => {
    try {
      const response = await api.get(`/crm/funnels/${funnelId}/?account_id=${account.id}&campaign_id=${selectedCampaign}`);
      setFunnel(response.data); // Set the funnel data

      // Check if this funnel is already the default
      const defaultResponse = await api.get(`/crm/campaigns/${selectedCampaign}/default-funnel/`);
      if (defaultResponse.data.funnel && parseInt(defaultResponse.data.funnel.id) === parseInt(funnelId)) {
        setIsDefault(true);
      } else {
        setIsDefault(false);
      }
    } catch (error) {
      console.error('Error fetching funnel details:', error);
      showSnackbar('Error fetching funnel details', 'error');
    }
  };

  // Fetch funnel steps when the funnel is loaded
  useEffect(() => {
    if (funnel) {
      fetchFunnelSteps();
    }
  }, [funnel]);

  const handleAddOrUpdateFunnel = async () => {
    try {  
      if (funnel.id) {
        // Update existing funnel
        await api.put(`/crm/funnels/${funnel.id}/`, funnel);
        showSnackbar('Funnel updated successfully', 'success');
      } else {
        // Create new funnel
        await api.post(`/crm/funnels/`, funnel);
        showSnackbar('Funnel added successfully', 'success');
      }
    } catch (error) {
      console.error('Error adding/updating funnel:', error);
      showSnackbar('Error adding/updating funnel', 'error');
    }
  };

  const handleDeleteFunnel = async (funnelId) => {
    try {
      await api.delete(`/crm/funnels/${funnelId}/`);
      showSnackbar('Funnel deleted successfully', 'success');
      navigate('/crm/funnels');
    } catch (error) {
      console.error('Error deleting funnel:', error);
      showSnackbar('Error deleting funnel', 'error');
    }
  };

  const fetchFunnelSteps = async () => {
    try {
      const response = await api.get(`/crm/steps/?funnel_id=${funnel.id}`);
      setFunnelSteps(response.data);
    } catch (error) {
      console.error('Error fetching funnel steps:', error);
      showSnackbar('Error fetching funnel steps', 'error');
    }
  };
  
  const handleAddOrUpdateStep = async () => {
    try {
      const stepData = editingStep || newStep;
      if (editingStep) {
        // Update step
        await api.put(`/crm/steps/${editingStep.id}/`, {
          ...stepData,
          funnel_id: funnel.id,  // Include the funnel_id in the request body
        });
      } else {
        // Add new step
        await api.post(`/crm/steps/`, {
          ...stepData,
          funnel_id: funnel.id,  // Include the funnel_id in the request body
        });
      }
      
      fetchFunnelSteps();
      setStepDialogOpen(false);
      setEditingStep(null);
      setNewStep({ name: '', order: 0, description: '', step_type: 'awareness' });
      showSnackbar(`Funnel step ${editingStep ? 'updated' : 'added'} successfully`, 'success');
    } catch (error) {
      console.error(`Error ${editingStep ? 'updating' : 'adding'} funnel step:`, error);
      showSnackbar(`Error ${editingStep ? 'updating' : 'adding'} funnel step`, 'error');
    }
  };

  // Function to open the confirmation dialog for deleting a step
  const openDeleteStepDialog = (stepId) => {
    setStepToDelete(stepId); // Store the step ID to delete
    setConfirmDeleteStepDialogOpen(true); // Open the dialog
  };

  // Function to close the delete confirmation dialog
  const closeDeleteStepDialog = () => {
    setConfirmDeleteStepDialogOpen(false);
    setStepToDelete(null); // Clear the step to delete
  };

  // Function to confirm and delete the step
  const handleConfirmDeleteStep = async () => {
    try {
      await api.delete(`/crm/steps/${stepToDelete}/`);
      fetchFunnelSteps(); // Refresh the steps list after deletion
      showSnackbar('Funnel step deleted successfully', 'success');
      closeDeleteStepDialog(); // Close the dialog after deletion
    } catch (error) {
      console.error('Error deleting funnel step:', error);
      showSnackbar('Error deleting funnel step', 'error');
    }
  };
  
  const handleDeleteStep = async (stepId) => {
    try {
      await api.delete(`/crm/steps/${stepId}/`);
      fetchFunnelSteps();
      showSnackbar('Funnel step deleted successfully', 'success');
    } catch (error) {
      console.error('Error deleting funnel step:', error);
      showSnackbar('Error deleting funnel step', 'error');
    }
  };  

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ open: false, message: '', severity: 'success' });
  };

  const openStepDialog = (step = null) => {
    if (step) {
      setEditingStep(step);
      setNewStep({ 
        name: step.name, 
        order: step.order, 
        description: step.description,
        step_type: step.step_type 
      });
    } else {
      setEditingStep(null);
      setNewStep({ 
        name: '', 
        order: funnelSteps.length + 1, 
        description: '',
        step_type: 'awareness'
      });
    }
    setStepDialogOpen(true);
  };

  // Function to open the confirmation dialog
  const openDeleteDialog = () => {
    setConfirmDeleteDialogOpen(true);
  };

  // Function to close the confirmation dialog
  const closeDeleteDialog = () => {
    setConfirmDeleteDialogOpen(false);
  };

  const handleDefaultChange = async (event) => {
    const checked = event.target.checked;

    if (checked) {
      try {
        const response = await api.get(`/crm/campaigns/${selectedCampaign}/default-funnel/`);
        if (response.data.funnel && response.data.funnel.id !== funnelId) {
          setDefaultWarningDialog(true); // Show the warning dialog
        } else {
          await setFunnelAsDefault();
        }
      } catch (error) {
        console.error('Error checking default funnel:', error);
      }
    } else {
      await removeDefaultFunnel(); // Unset the default funnel
    }
  };

  const setFunnelAsDefault = async () => {
    try {
      await api.post(`/crm/campaigns/${selectedCampaign}/set-default-funnel/`, { funnel_id: funnelId });
      setIsDefault(true);
      showSnackbar('Funnel set as default successfully', 'success');
    } catch (error) {
      console.error('Error setting funnel as default:', error);
      showSnackbar('Error setting funnel as default', 'error');
    }
  };

  const removeDefaultFunnel = async () => {
    try {
      await api.post(`/crm/campaigns/${selectedCampaign}/remove-default-funnel/`);
      setIsDefault(false);
      showSnackbar('Funnel unset as default successfully', 'success');
    } catch (error) {
      console.error('Error unsetting default funnel:', error);
      showSnackbar('Error unsetting default funnel', 'error');
    }
  };

  const handleConfirmChangeDefault = async () => {
    await setFunnelAsDefault();
    setDefaultWarningDialog(false);
  };

  return (
    <Box sx={{
      padding: 2,
      backgroundColor: theme.palette.background.default,
      minHeight: '100vh',
    }}>
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate('/crm/funnels')}
        sx={{ mb: 2 }}
      >
        Back to Funnels
      </Button>

      <Typography variant="h4" gutterBottom color="textPrimary">
        Funnel Settings: {funnel?.name}
      </Typography>

      <Tabs value={tabValue} onChange={(e, newValue) => setTabValue(newValue)}>
        <Tab label="General Information" />
        <Tab label="Funnel Step Management" />
      </Tabs>

      <Box sx={{ padding: 2 }}>
        {/* General Information Tab */}
        {tabValue === 0 && funnel && (
          <>
            <TextField
              label="Account Name"
              value={funnel.account_name || ''}
              fullWidth
              disabled
              sx={{ mb: 2, color: theme.palette.text.primary }}
            />

            <TextField
              label="Campaign Name"
              value={funnel.campaign_name || ''}
              fullWidth
              disabled
              sx={{ mb: 2, color: theme.palette.text.primary }}
            />

            <TextField
              label="Funnel Name"
              value={funnel?.name || ''}
              fullWidth
              onChange={(e) => setFunnel({ ...funnel, name: e.target.value })}
              sx={{
                mb: 2,
                backgroundColor: theme.palette.background.paper,
                input: { color: theme.palette.text.primary },
                label: { color: theme.palette.text.secondary },
              }}
            />

            <TextField
              label="Funnel Description"
              value={funnel?.description || ''}
              fullWidth
              multiline
              rows={3}
              onChange={(e) => setFunnel({ ...funnel, description: e.target.value })}
              sx={{
                mb: 2,
                backgroundColor: theme.palette.background.paper,
                input: { color: theme.palette.text.primary },
                label: { color: theme.palette.text.secondary },
              }}
            />

            {/* Direction Input Field */}
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="direction-label">Direction</InputLabel>
              <Select
                labelId="direction-label"
                name="direction"
                value={funnel.direction ? funnel.direction.charAt(0).toUpperCase() + funnel.direction.slice(1) : ''}
                onChange={(e) => setFunnel({ ...funnel, direction: e.target.value.toLowerCase() })}
                label="Direction"
                sx={{
                  mb: 2,
                  backgroundColor: theme.palette.background.paper,
                  input: { color: theme.palette.text.primary },
                  label: { color: theme.palette.text.secondary },
                }}
              >
                <MenuItem value="Inbound">Inbound</MenuItem>
                <MenuItem value="Outbound">Outbound</MenuItem>
              </Select>
            </FormControl>

            {/* Fixed Start Date Field */}
            <TextField
              label="Start Date"
              name="start_date"
              type="date"
              fullWidth
              value={funnel?.start_date || ''}
              onChange={(e) => setFunnel({ ...funnel, start_date: e.target.value })}
              sx={{
                mb: 2,
                backgroundColor: theme.palette.background.paper,
                input: { color: theme.palette.text.primary },
                label: { color: theme.palette.text.secondary },
              }}
              InputLabelProps={{
                shrink: true, // Ensures the label does not overlap
              }}
            />

            {/* Fixed End Date Field */}
            <TextField
              label="End Date"
              name="end_date"
              type="date"
              fullWidth
              value={funnel?.end_date || ''}
              onChange={(e) => setFunnel({ ...funnel, end_date: e.target.value })}
              sx={{
                mb: 2,
                backgroundColor: theme.palette.background.paper,
                input: { color: theme.palette.text.primary },
                label: { color: theme.palette.text.secondary },
              }}
              InputLabelProps={{
                shrink: true, // Ensures the label does not overlap
              }}
            />

            {/* Data Retention Period */}
            <TextField
              label="Data Retention Period (days)"
              name="data_retention_period"
              type="number"
              fullWidth
              value={funnel?.data_retention_period || ''}
              onChange={(e) => setFunnel({ ...funnel, data_retention_period: e.target.value })}
              sx={{
                mb: 2,
                backgroundColor: theme.palette.background.paper,
                input: { color: theme.palette.text.primary },
                label: { color: theme.palette.text.secondary },
              }}
            />

            {/* Data Retention Period */}
            <TextField
              label="Pathway ID (AI Agent)"
              name="pathway_id"
              fullWidth
              value={funnel?.pathway_id || ''}
              onChange={(e) => setFunnel({ ...funnel, pathway_id: e.target.value })}
              sx={{
                mb: 2,
                backgroundColor: theme.palette.background.paper,
                input: { color: theme.palette.text.primary },
                label: { color: theme.palette.text.secondary },
              }}
            />

            {/* Status */}
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                labelId="status-label"
                value={funnel?.active ? 'active' : 'inactive'}
                onChange={(e) => setFunnel({ ...funnel, active: e.target.value === 'active' })}
                label="Status"
                sx={{
                  mb: 2,
                  backgroundColor: theme.palette.background.paper,
                  input: { color: theme.palette.text.primary },
                  label: { color: theme.palette.text.secondary },
                }}
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
              </Select>
            </FormControl>

            {/* Add the toggle checkbox to set the funnel as default */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mb: 2 }}>
              <Typography variant="body1" sx={{ mr: 1, color: theme.palette.text.primary }}>
                Set as Default Funnel for Campaign:
              </Typography>
              <Checkbox
                checked={isDefault}
                onChange={handleDefaultChange}
                color="primary"
              />
            </Box>

            {/* Update and Delete buttons */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <Button
                variant="contained"
                onClick={handleAddOrUpdateFunnel}
                sx={{
                  backgroundColor: 'primary.main',
                  color: 'white',
                  ':hover': {
                    backgroundColor: 'primary.dark',
                  },
                }}
              >
                Update Funnel
              </Button>

              <Button
                variant="contained"
                // onClick={() => handleDeleteFunnel(funnel?.id)}
                sx={{
                  backgroundColor: 'red',
                  color: 'white',
                  ':hover': {
                    backgroundColor: 'darkred',
                  },
                }}
                onClick={openDeleteDialog}  // Open the delete confirmation dialog
              >
                Delete Funnel
              </Button>
            </Box>
          </>
        )}

        {/* Funnel Step Management Tab */}
        {tabValue === 1 && funnel && (
          <>
            <Typography variant="h6" gutterBottom sx={{ mb: 3 }} color="textPrimary">
              Funnel Steps
            </Typography>
            <TableContainer component={Paper} sx={{ mb: 4 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Order</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Step Type</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {funnelSteps.map((step) => (
                    <TableRow key={step.id}>
                      <TableCell>{step.order}</TableCell>
                      <TableCell>{step.name}</TableCell>
                      <TableCell>{step.description}</TableCell>
                      <TableCell>
                        {step.step_type && (
                          <Chip 
                            icon={STEP_TYPE_OPTIONS.find(opt => opt.value === step.step_type)?.icon}
                            label={STEP_TYPE_OPTIONS.find(opt => opt.value === step.step_type)?.label || step.step_type}
                            color={STEP_TYPE_OPTIONS.find(opt => opt.value === step.step_type)?.color || 'default'}
                            size="small"
                            sx={{ 
                              '& .MuiChip-icon': { 
                                marginLeft: '8px',
                                marginRight: '-4px' 
                              }
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => openStepDialog(step)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => openDeleteStepDialog(step.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Button 
              startIcon={<AddIcon />} 
              onClick={() => openStepDialog()}
              variant="contained" 
              color="primary"
              sx={{ mb: 4 }}
            >
              Add Step
            </Button>
          </>
        )}
      </Box>

      {/* Funnel Step Dialog */}
      <Dialog open={stepDialogOpen} onClose={() => setStepDialogOpen(false)}>
        <DialogTitle>
          {editingStep ? 'Edit Step' : 'Add New Step'}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Step Name"
            type="text"
            fullWidth
            value={editingStep ? editingStep.name : newStep.name}
            onChange={(e) => {
              if (editingStep) {
                setEditingStep({ ...editingStep, name: e.target.value });
              } else {
                setNewStep({ ...newStep, name: e.target.value });
              }
            }}
          />
          <TextField
            margin="dense"
            label="Order"
            type="number"
            fullWidth
            value={editingStep ? editingStep.order : newStep.order}
            onChange={(e) => {
              const order = parseInt(e.target.value, 10);
              if (editingStep) {
                setEditingStep({ ...editingStep, order });
              } else {
                setNewStep({ ...newStep, order });
              }
            }}
          />
          <TextField
            margin="dense"
            label="Description"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={editingStep ? editingStep.description : newStep.description}
            onChange={(e) => {
              if (editingStep) {
                setEditingStep({ ...editingStep, description: e.target.value });
              } else {
                setNewStep({ ...newStep, description: e.target.value });
              }
            }}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Step Type</InputLabel>
            <Select
              value={editingStep ? editingStep.step_type : newStep.step_type}
              onChange={(e) => {
                if (editingStep) {
                  setEditingStep({ ...editingStep, step_type: e.target.value });
                } else {
                  setNewStep({ ...newStep, step_type: e.target.value });
                }
              }}
              label="Step Type"
            >
              {STEP_TYPE_OPTIONS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {option.icon}
                    {option.label}
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setStepDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleAddOrUpdateStep} color="primary">
            {editingStep ? 'Update' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={confirmDeleteDialogOpen} onClose={closeDeleteDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this funnel? This action cannot be undone.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDeleteFunnel(funnel?.id);
              closeDeleteDialog();
            }}
            sx={{
              color: 'white',
              backgroundColor: 'red',
              ':hover': {
                backgroundColor: 'darkred',
              },
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Step Confirmation Dialog */}
      <Dialog open={confirmDeleteStepDialogOpen} onClose={closeDeleteStepDialog}>
          <DialogTitle>Confirm Step Deletion</DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to delete this funnel step? This action cannot be undone.</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDeleteStepDialog} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleConfirmDeleteStep} // Handle the confirmed deletion
              sx={{
                color: 'white',
                backgroundColor: 'red',
                ':hover': {
                  backgroundColor: 'darkred',
                },
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        {/* Warning Dialog if funnel is being overwritten */}
        <Dialog
          open={defaultWarningDialog}
          onClose={() => setDefaultWarningDialog(false)}
        >
          <DialogTitle>Warning</DialogTitle>
          <DialogContent>
            <Typography>
              Another funnel is currently the default for this campaign. Are you sure you want to overwrite it?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDefaultWarningDialog(false)} color="primary">Cancel</Button>
            <Button onClick={handleConfirmChangeDefault} color="secondary">Confirm</Button>
          </DialogActions>
        </Dialog>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default FunnelSettings;
