// src/components/Layout.js

import React, { useState, useContext } from 'react';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Collapse,
  Divider,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Dashboard,
  People,
  ExitToApp,
  ExpandLess,
  ExpandMore,
  Settings as SettingsIcon,
  Campaign as CampaignIcon,
  Notifications as NotificationsIcon,
  IntegrationInstructions as IntegrationsIcon,
  ChevronLeft,
  ChevronRight,
} from '@mui/icons-material';
import { api } from '../services/authService';
import { useDispatch, useSelector } from 'react-redux';
import { logOut, selectCurrentToken } from '../redux/authSlice';
import { AuthContext } from '../contexts/AuthContext';
import logoImage from '../assets/NovauraLogo.png';
import circleLogoImage from '../assets/NovauraCircleIcon.png';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../theme/theme';
import TopNavBar from './TopNavBar';

const drawerWidth = 240;
const collapsedDrawerWidth = 60; // Width when minimized

const Layout = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation(); // To detect the current page
  const token = useSelector(selectCurrentToken);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const toggleSubMenu = (key) => {
    setOpenSubMenu((prevState) => ({
      ...prevState,
      [key]: !prevState[key], // Toggle the specific submenu key
    }));
  };

  const handleSignOut = async () => {
    try {
      const refreshToken = localStorage.getItem('refreshToken');
      if (refreshToken) {
        await api.post(
          '/accounts/logout/',
          { refresh_token: refreshToken },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
      }

      // Clear local storage
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('user');

      // Dispatch logout action to clear Redux state
      dispatch(logOut());

      // Navigate to login page
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  const menuItems = [
    {
      text: 'Home',
      icon: <Dashboard />,
      subItems: [{ text: 'Dashboard', path: '/crm/dashboard' }],
    },
    {
      text: 'Lead Management',
      icon: <People />,
      subItems: [
        { text: 'Leads', path: '/crm/leads' },
        { text: 'Tasks', path: '/crm/tasks' },
      ],
    },
    {
      text: 'Builder',
      icon: <CampaignIcon />,
      subItems: [
        { text: 'Accounts', path: '/crm/accounts' },
        { text: 'Campaigns', path: '/crm/campaigns' },
        { text: 'Funnels', path: '/crm/funnels' },
        // { text: 'Agents', path: '/crm/agent-dashboard' },
      ],
    },
    {
      text: 'Settings',
      icon: <SettingsIcon />,
      subItems: [
        { text: 'User Profile', path: '/crm/profile' },
      ],
    },
    {
      text: 'Notifications',
      icon: <NotificationsIcon />,
      subItems: [
        { text: 'View Notifications', path: '/crm/notifications' },
        { text: 'Notification Rules', path: '/crm/notification-rules' },
      ],
    },
    // {
    //   text: 'Integrations',
    //   icon: <IntegrationsIcon />,
    //   path: '/crm/integrations'
    // },
    {
      text: 'Logout',
      icon: <ExitToApp />,
      action: handleSignOut, // Use the action to handle logout
    },
  ];

  const handleMenuClick = (item, key) => {
    if (!open) {
      // Expand sidebar before navigating or performing actions
      setOpen(true);
    } else {
      // Perform the original action, such as navigating or triggering a submenu
      if (item.subItems) {
        toggleSubMenu(key);
      }
      if (item.path) {
        navigate(item.path);
      }
      if (item.action) {
        item.action();
      }
    }
  };

  const renderMenuItems = (items, parentKey = '') => {
    return items.map((item, index) => {
      const key = `${parentKey}${index}`;
      const listItemProps = {
        button: true,
        key: key,
        onClick: () => handleMenuClick(item, key),
      };

      return (
        <React.Fragment key={key}>
          <ListItem {...listItemProps}>
            <ListItemIcon sx={{ color: theme.palette.text.primary }}>
              {item.icon}
            </ListItemIcon>
            <ListItemText
              primary={item.text}
              primaryTypographyProps={{ style: { color: theme.palette.text.primary } }}
              sx={{ display: open ? 'block' : 'none' }} // Hide text when sidebar is minimized
            />
            {item.subItems && open && (openSubMenu[key] ? <ExpandLess /> : <ExpandMore />)}
          </ListItem>
          {item.subItems && open && (
            <Collapse in={openSubMenu[key]} timeout="auto" unmountOnExit>
              <Divider sx={{ backgroundColor: theme.palette.text.secondary, my: 1 }} />
              <List component="div" disablePadding>
                {renderMenuItems(item.subItems, key)}
              </List>
              <Divider sx={{ backgroundColor: theme.palette.text.secondary, my: 1 }} />
            </Collapse>
          )}
        </React.Fragment>
      );
    });
  };

  // Hide menu icon on login page
  const isLoginPage =
    location.pathname === '/login' ||
    location.pathname === '/signup' ||
    location.pathname === '/activate' ||
    location.pathname === '/reset-password' ||
    location.pathname === '/set-password' ||
    location.pathname === '/forgot-password';

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', minHeight: '100vh', backgroundColor: theme.palette.background.default }}>
        {/* Sidebar */}
        {!isLoginPage && (
          <Drawer
            variant="permanent"
            sx={{
              width: open ? drawerWidth : collapsedDrawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: open ? drawerWidth : collapsedDrawerWidth,
                backgroundColor: theme.palette.background.paper,
                transition: theme.transitions.create('width', {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
                }),
                boxSizing: 'border-box',
              },
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              {/* Logo Section */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '20px',
                  backgroundColor: theme.palette.background.paper,
                }}
              >
                <img
                  src={open ? logoImage : circleLogoImage}
                  alt="Novaura Logo"
                  style={{ width: open ? 190 : 40 }}
                />
              </Box>

              {/* Menu Items */}
              <List>{renderMenuItems(menuItems)}</List>

              {/* Spacer */}
              <Box sx={{ flexGrow: 1 }} />

              {/* Drawer Toggle Button */}
              <IconButton
                onClick={toggleDrawer}
                sx={{
                  alignSelf: 'center',
                  marginBottom: 2,
                  color: theme.palette.text.primary,
                }}
              >
                {open ? <ChevronLeft /> : <ChevronRight />}
              </IconButton>
            </Box>
          </Drawer>
        )}

        {/* Main Content Area */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            backgroundColor: theme.palette.background.default,
            overflowX: 'hidden', // Prevent horizontal overflow in main area
          }}
        >
          {/* Top Navbar */}
          {!isLoginPage && <TopNavBar open={open} handleDrawerToggle={handleDrawerToggle} />}

          {/* Adjust Main Content to account for AppBar height */}
          <Box
            sx={{
              mt: !isLoginPage ? 8 : 0, // Adjust margin-top based on AppBar height
              p: 2,
              width: '100%', // Ensure content takes full width without exceeding
              maxWidth: '100%',
              overflowX: 'hidden', // Prevent overflow within content
              boxSizing: 'border-box', // Include padding within the width
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Layout;
