// src/components/EditTaskDialog.js

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  MenuItem,
  Box,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { updateTaskThunk } from '../redux/tasksSlice';
import { api } from '../services/authService';
import moment from 'moment-timezone';
import { DateTimePicker } from '@mui/x-date-pickers';

const EditTaskDialog = ({
  open,
  handleClose,
  task,
  accountId,
  campaignId,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const timezone = user?.timezone || 'UTC';

  const [leads, setLeads] = useState([]);
  const [currentLeadName, setCurrentLeadName] = useState('');

  const [formData, setFormData] = useState({
    title: '',
    description: '',
    due_date: null,
    priority: 'medium',
    status: 'pending',
    assigned_to: 'Unassigned',
    lead: '', 
  });
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (task) {
      const dueDateLocal = task.due_date
        ? moment.utc(task.due_date).tz(timezone)
        : null;

      // Set the current lead name if there's a related lead
      if (task.related_lead) {
        setCurrentLeadName(`${task.related_lead.first_name} ${task.related_lead.last_name}`);
      }

      setFormData({
        title: task.title || '',
        description: task.description || '',
        due_date: dueDateLocal,
        priority: task.priority || 'medium',
        status: task.status || 'pending',
        assigned_to: task.assigned_to?.id || 'Unassigned',
        lead: task.related_lead?.id || '',
      });
    }
  }, [task, timezone]);

  useEffect(() => {
    const fetchLeads = async () => {
      try {
        const response = await api.get(`/crm/leads/?campaign_id=${campaignId}`);
        if (response.data.results && Array.isArray(response.data.results)) {
          setLeads(response.data.results);
        }
      } catch (error) {
        console.error('Error fetching leads:', error);
      }
    };
    
    if (campaignId) {
      fetchLeads();
    }
  }, [campaignId]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await api.get(
          `/crm/accounts/${accountId}/campaigns/users/?campaign_id=${campaignId}`
        );
        setUsers(response.data);

        if (
          task.assigned_to &&
          response.data.some((user) => user.id === task.assigned_to.id)
        ) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            assigned_to: task.assigned_to.id,
          }));
        } else {
          setFormData((prevFormData) => ({
            ...prevFormData,
            assigned_to: 'Unassigned',
          }));
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    if (accountId && campaignId) {
      fetchUsers();
    }
  }, [accountId, campaignId, task.assigned_to]);

  const handleSubmit = () => {
    // Convert due_date from user's timezone to UTC using moment-timezone
    let dueDateUtc = null;
    if (formData.due_date) {
      dueDateUtc = moment
        .tz(formData.due_date, timezone)
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss[Z]');
    }

    const updatedTaskData = {
      ...formData,
      due_date: dueDateUtc,
      campaign: campaignId, // Include the campaign field
      assigned_to:
        formData.assigned_to === 'Unassigned' ? null : formData.assigned_to,
      related_lead_id: formData.lead || null,
    };

    dispatch(updateTaskThunk({ taskId: task.id, taskData: updatedTaskData }));
    handleClose();
  };

  if (!task) return null;

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Edit Task</DialogTitle>
      <DialogContent>
        {/* Title */}
        <TextField
          label="Title"
          fullWidth
          value={formData.title}
          onChange={(e) => setFormData({ ...formData, title: e.target.value })}
          margin="normal"
        />
        {/* Description */}
        <TextField
          label="Description"
          fullWidth
          multiline
          rows={4}
          value={formData.description}
          onChange={(e) =>
            setFormData({ ...formData, description: e.target.value })
          }
          margin="normal"
        />
        {/* Due Date */}
        <DateTimePicker
          label="Due Date"
          value={formData.due_date}
          onChange={(newValue) =>
            setFormData({ ...formData, due_date: newValue })
          }
          renderInput={(params) => (
            <TextField {...params} fullWidth margin="normal" />
          )}
        />
        {/* Lead Field */}
        <TextField
          label="Select Lead (Optional)"
          select
          fullWidth
          value={formData.lead}
          onChange={(e) => setFormData({ ...formData, lead: e.target.value })}
          margin="normal"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {/* Show current lead first if it exists and isn't in the fetched leads */}
          {currentLeadName && formData.lead && 
           !leads.some(lead => lead.id === formData.lead) && (
            <MenuItem value={formData.lead}>
              {currentLeadName}
            </MenuItem>
          )}
          {leads.map((lead) => (
            <MenuItem key={lead.id} value={lead.id}>
              {`${lead.first_name} ${lead.last_name}`}
            </MenuItem>
          ))}
        </TextField>
        {/* Priority */}
        <TextField
          label="Priority"
          select
          fullWidth
          value={formData.priority}
          onChange={(e) =>
            setFormData({ ...formData, priority: e.target.value })
          }
          margin="normal"
        >
          <MenuItem value="low">Low</MenuItem>
          <MenuItem value="medium">Medium</MenuItem>
          <MenuItem value="high">High</MenuItem>
        </TextField>
        {/* Status */}
        <TextField
          label="Status"
          select
          fullWidth
          value={formData.status}
          onChange={(e) =>
            setFormData({ ...formData, status: e.target.value })
          }
          margin="normal"
        >
          <MenuItem value="pending">Pending</MenuItem>
          <MenuItem value="completed">Completed</MenuItem>
        </TextField>
        {/* Assigned To */}
        <TextField
          label="Assigned To"
          select
          fullWidth
          value={formData.assigned_to}
          onChange={(e) =>
            setFormData({ ...formData, assigned_to: e.target.value })
          }
          margin="normal"
        >
          <MenuItem value="Unassigned">Unassigned</MenuItem>
          {users.map((user) => (
            <MenuItem key={user.id} value={user.id}>
              {`${user.first_name} ${user.last_name}`}
            </MenuItem>
          ))}
        </TextField>
        {/* Buttons */}
        <Box mt={2}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Save Changes
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            sx={{ ml: 2 }}
          >
            Cancel
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EditTaskDialog;