// src/components/CampaignList.jsx
import React from 'react';
import { List, ListItem, ListItemText, Paper } from '@mui/material';

export default function CampaignList({ campaigns, selectedCampaign, onSelect }) {
  return (
    <Paper>
      <List>
        {campaigns.map((campaign) => (
          <ListItem
            key={campaign.id}
            button
            selected={selectedCampaign?.id === campaign.id}
            onClick={() => onSelect(campaign)}
          >
            <ListItemText
              primary={campaign.name}
              secondary={`${campaign.funnels.length} ${
                campaign.funnels.length === 1 ? 'funnel' : 'funnels'
              }`}
            />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
}