// src/services/dashboardService.js
import { api } from './authService';

export const fetchDashboardData = async (queryString = '') => {
  try {
    // Add the ? before the queryString if it exists
    const url = `crm/dashboard/${queryString ? `?${queryString}` : ''}`;
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};
