// src/pages/Dashboard.js

import React, { useEffect, useState } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Box,
  Avatar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  Drawer,
  IconButton,
  Badge,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Chip,
  Stack,
  Tooltip as MuiTooltip,
} from '@mui/material';
import {
  Group as GroupIcon,
  TrendingUp as TrendingUpIcon,
  Campaign as CampaignIcon,
  Message as MessageIcon,
  SentimentSatisfied as SentimentSatisfiedIcon,
  FilterList as FilterIcon,
  Clear as ClearIcon,
} from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  FunnelChart,
  Funnel,
  LabelList,
} from 'recharts';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { fetchDashboardData, fetchFilterOptions } from '../services/dashboardService';
import theme from '../theme/theme'; 
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './CalendarStyles.css'; // Import after the default styles
import { DatePicker } from '@mui/x-date-pickers';
import { api } from '../services/authService';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`dashboard-tabpanel-${index}`}
      aria-labelledby={`dashboard-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);

  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [filters, setFilters] = useState({
    accountId: '',
    campaignId: [],
    funnelId: [],
    startDate: null,
    endDate: null,
  });
  const [pendingFilters, setPendingFilters] = useState({
    accountId: '',
    campaignId: [],
    funnelId: [],
    startDate: null,
    endDate: null,
  });
  
  const [accounts, setAccounts] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [funnels, setFunnels] = useState([]);
  const [activeFilterCount, setActiveFilterCount] = useState(0);

  // Add loading states
  const [accountsLoading, setAccountsLoading] = useState(true);
  const [campaignsLoading, setCampaignsLoading] = useState(true);

  // Fetch accounts with loading state
  useEffect(() => {
    const fetchAccounts = async () => {
      setAccountsLoading(true);
      try {
        const response = await api.get('/crm/accounts/');
        setAccounts(response.data);
      } catch (error) {
        console.error('Error fetching accounts:', error);
      } finally {
        setAccountsLoading(false);
      }
    };
    fetchAccounts();
  }, []);

  // Fetch campaigns when selected account changes
  useEffect(() => {
    const fetchCampaigns = async () => {
      if (pendingFilters.accountId) {
        setCampaignsLoading(true);
        try {
          const response = await api.get(`/crm/campaigns/?account_id=${pendingFilters.accountId}`);
          setCampaigns(response.data);
        } catch (error) {
          console.error('Error fetching campaigns:', error);
        } finally {
          setCampaignsLoading(false);
        }
      } else {
        setCampaigns([]);
      }
    };
    fetchCampaigns();
  }, [pendingFilters.accountId]);

  // Validate campaign selection when campaigns change
  useEffect(() => {
    if (!campaignsLoading && pendingFilters.campaignId.length > 0) {
      const validCampaigns = pendingFilters.campaignId.filter(id =>
        campaigns.some(campaign => String(campaign.id) === String(id))
      );
  
      // Only update if there's actually a change needed
      if (validCampaigns.length !== pendingFilters.campaignId.length) {
        setPendingFilters(prev => ({
          ...prev,
          campaignId: validCampaigns,
          funnelId: []
        }));
      }
    }
  }, [campaignsLoading, campaigns]);

  // Fetch funnels when selected campaign changes
  useEffect(() => {
    const fetchFunnels = async () => {
      if (pendingFilters.campaignId.length > 0) {
        try {
          // Create query string for multiple campaign IDs
          const campaignIds = pendingFilters.campaignId.join(',');
          const response = await api.get(`/crm/funnels/?campaign_id=${campaignIds}`);
          setFunnels(response.data);
        } catch (error) {
          console.error('Error fetching funnels:', error);
        }
      } else {
        setFunnels([]);
      }
    };
    fetchFunnels();
  }, [pendingFilters.campaignId]);

  // Add this useEffect to count active filters
  useEffect(() => {
    let count = 0;
    
    if (filters.accountId) count++;
    if (filters.campaignId.length > 0) count++;
    if (filters.funnelId.length > 0) count++;
    if (filters.startDate) count++;
    if (filters.endDate) count++;
    
    setActiveFilterCount(count);
  }, [filters]);

  // Update your handleFilterChange function to handle arrays
  const handleFilterChange = (field, value) => {
    setPendingFilters(prev => {
      const newFilters = { ...prev };
      
      // If changing account, reset campaign and funnel selections
      if (field === 'accountId') {
        newFilters.accountId = value;
        newFilters.campaignId = [];
        newFilters.funnelId = [];
      } else if (field === 'startDate' || field === 'endDate') {
        newFilters[field] = value;
      } else if (field === 'campaignId' || field === 'funnelId') {
        newFilters[field] = Array.isArray(value) ? value : []; 
      } else {
        newFilters[field] = value;
      }
      
      return newFilters;
    });
  };

  // Update handleClearFilters to clear both filters and pendingFilters
  const handleClearFilters = () => {
    const emptyFilters = {
      accountId: '',
      campaignId: [],
      funnelId: [],
      startDate: null,
      endDate: null,
    };
    setFilters(emptyFilters);
    setPendingFilters(emptyFilters);
  };

  // Add handleApplyFilters function
  const handleApplyFilters = () => {
    setFilters(pendingFilters);
    setFilterDrawerOpen(false);
  };

  // Update your fetchDashboardDataRunner to handle multiple selections
  const fetchDashboardDataRunner = async () => {
    try {
      const queryParams = new URLSearchParams();
      if (filters.accountId) queryParams.append('account_id', filters.accountId);
      
      // Handle multiple campaign IDs
      filters.campaignId.forEach(id => {
        queryParams.append('campaign_id', id);
      });
      
      // Handle multiple funnel IDs
      filters.funnelId.forEach(id => {
        queryParams.append('funnel_id', id);
      });
      
      if (filters.startDate) queryParams.append('start_date', filters.startDate.toISOString());
      if (filters.endDate) queryParams.append('end_date', filters.endDate.toISOString());

      const data = await fetchDashboardData(queryParams.toString());
      setDashboardData(data);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      setDashboardData(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }
    fetchDashboardDataRunner();
  }, [filters, token, navigate]);
  
  const localizer = momentLocalizer(moment);

  const calendarEvents =
    dashboardData?.upcoming_tasks?.map((task) => ({
      title: task.title,
      start: new Date(task.due_date),
      end: new Date(task.due_date),
    })) || [];

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" mt={10}>
        <CircularProgress />
      </Box>
    );
  }

  if (!dashboardData) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        sx={{
          mt: 10,
          background: `linear-gradient(to bottom, ${theme.palette.background.default}, ${theme.palette.background.paper})`,
          minHeight: '100vh',
          padding: 2,
        }}
      >
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{
            color: theme.palette.text.primary,
            fontWeight: 700,
          }}
        >
          Welcome to your Novaura CRM!
        </Typography>
        <Typography
          variant="body1"
          align="center"
          gutterBottom
          sx={{
            color: theme.palette.text.secondary,
            fontWeight: 500,
          }}
        >
          It looks like you haven’t set up any data yet. Let’s get started by configuring your account.
        </Typography>
        <Grid container spacing={2} justifyContent="center" sx={{ mt: 4 }}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              sx={{
                fontWeight: 700,
                textTransform: 'none',
                padding: '12px 24px',
                borderRadius: '8px',
                fontSize: '16px',
                borderColor: theme.palette.secondary.main,
                color: theme.palette.text.primary,
              }}
              onClick={() => navigate('/crm/accounts')}
            >
              Configure your account!
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  }

  // Destructure data with default values
  const {
    total_leads = 0,
    leads_converted = 0,
    active_campaigns = 0,
    interactions_today = 0,
    sentiment_score = 0,
    leads_funnel = [],
    sentiment_analysis = [],
    top_high_priority_leads = [],
    top_conversion_leads = [],
    pipeline_velocity = [],
    activity_feed = [],
    recent_interactions = [],
    top_performers = [],
    upcoming_tasks = [],
  } = dashboardData;

  // Sample data for charts if none exists
  const sampleFunnelData = [
    { stage: 'New Lead', count: 0 },
    { stage: 'Contacted', count: 0 },
    { stage: 'Qualified', count: 0 },
    { stage: 'Converted', count: 0 },
  ];

  const sampleSentimentData = [
    { name: 'Positive', value: 0 },
    { name: 'Neutral', value: 0 },
    { name: 'Negative', value: 0 },
  ];

  const COLORS = [
    theme.palette.success.main,
    theme.palette.warning.main,
    theme.palette.error.main,
  ];

  const funnelData = leads_funnel.map((item) => ({
    name: item.stage,
    value: item.count,
  }));

  // Helper function for color coding
  const getPriorityColor = (score) => {
    let backgroundColor;

    if (score >= 80) {
      backgroundColor = theme.palette.error.light; // High priority - light red
    } else if (score >= 50) {
      backgroundColor = theme.palette.warning.light; // Medium priority - light orange
    } else {
      backgroundColor = theme.palette.success.light; // Low priority - light green
    }

    const textColor = theme.palette.getContrastText(backgroundColor);

    return { backgroundColor, textColor };
  };

  const handleFunnelClick = (data) => {
    const stage = data.payload.name;
    // Navigate to a page showing detailed leads in that stage
    navigate(`/crm/leads?stage=${encodeURIComponent(stage)}`);
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4" sx={{ color: theme.palette.text.primary }}>
          Dashboard
        </Typography>
        <MuiTooltip title="Toggle Filters" arrow placement="left">
          <IconButton onClick={() => setFilterDrawerOpen(!filterDrawerOpen)}>
            <Badge badgeContent={activeFilterCount} color="primary">
              <FilterIcon />
            </Badge>
          </IconButton>
        </MuiTooltip>
      </Box>

      {/* Filter Drawer */}
      <Drawer
        anchor="right"
        open={filterDrawerOpen}
        onClose={() => setFilterDrawerOpen(false)}
        PaperProps={{
          sx: {
            width: 320,
            p: 3,
            backgroundColor: theme.palette.background.paper,
          },
        }}
      >
        <Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
            <Typography variant="h6">Filters</Typography>
            <MuiTooltip title="Hide Filters">
              <IconButton onClick={() => setFilterDrawerOpen(false)} size="small">
                <ClearIcon />
              </IconButton>
            </MuiTooltip>
          </Box>

          <Stack spacing={3}>
            {/* Account Filter (remains single select) */}
            <FormControl fullWidth>
              <InputLabel>Account</InputLabel>
              <Select
                value={pendingFilters.accountId}
                onChange={(e) => handleFilterChange('accountId', e.target.value)}
                label="Account"
                disabled={accountsLoading}
              >
                <MenuItem value="">All Accounts</MenuItem>
                {accounts.map((account) => (
                  <MenuItem key={account.id} value={account.id}>
                    {account.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Campaign Filter */}
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel>Campaigns</InputLabel>
              <Select
                multiple
                value={Array.isArray(pendingFilters.campaignId) ? pendingFilters.campaignId : []}  // Changed
                onChange={(e) => handleFilterChange('campaignId', e.target.value)}
                label="Campaigns"
                disabled={campaignsLoading || !pendingFilters.accountId}  // Changed
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {Array.isArray(selected) && selected.map((value) => {
                      const campaign = campaigns.find(c => c.id === value);
                      return (
                        <Chip
                          key={value}
                          label={campaign ? campaign.name : value}
                          size="small"
                        />
                      );
                    })}
                  </Box>
                )}
              >
                {campaigns.map((campaign) => (
                  <MenuItem key={campaign.id} value={campaign.id}>
                    {campaign.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Funnel Filter */}
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel>Funnels</InputLabel>
              <Select
                multiple
                value={pendingFilters.funnelId}  // Changed
                onChange={(e) => handleFilterChange('funnelId', e.target.value)}
                label="Funnels"
                disabled={!pendingFilters.accountId || pendingFilters.campaignId.length === 0}  // Changed
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => {
                      const funnel = funnels.find(f => f.id === value);
                      return (
                        <Chip
                          key={value}
                          label={funnel ? funnel.name : value}
                          size="small"
                        />
                      );
                    })}
                  </Box>
                )}
              >
                {funnels.map((funnel) => (
                  <MenuItem key={funnel.id} value={funnel.id}>
                    {funnel.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Date Range Filters */}
            <DatePicker
              label="Start Date"
              value={pendingFilters.startDate}
              onChange={(date) => handleFilterChange('startDate', date)}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
            <DatePicker
              label="End Date"
              value={pendingFilters.endDate}
              onChange={(date) => handleFilterChange('endDate', date)}
              renderInput={(params) => <TextField {...params} fullWidth />}
              minDate={pendingFilters.startDate}
            />
          </Stack>

          {/* Active Filters Display */}
          {activeFilterCount > 0 && (
            <Box mt={3}>
              <Typography variant="subtitle2" gutterBottom>
                Active Filters:
              </Typography>
              <Stack direction="row" spacing={1} flexWrap="wrap">
                {pendingFilters.accountId && (
                  <Chip
                    key="account"
                    label={`Account: ${accounts.find(a => a.id === pendingFilters.accountId)?.name || pendingFilters.accountId}`}
                    onDelete={() => handleFilterChange('accountId', '')}
                    size="small"
                    sx={{ mt: 1 }}
                  />
                )}
                
                {pendingFilters.campaignId.map(id => {
                  const campaign = campaigns.find(c => c.id === id);
                  return (
                    <Chip
                      key={`campaign-${id}`}
                      label={`Campaign: ${campaign?.name || id}`}
                      onDelete={() => {
                        const newCampaigns = pendingFilters.campaignId.filter(cId => cId !== id);
                        handleFilterChange('campaignId', newCampaigns);
                      }}
                      size="small"
                      sx={{ mt: 1 }}
                    />
                  );
                })}
                
                {pendingFilters.funnelId.map(id => {
                  const funnel = funnels.find(f => f.id === id);
                  return (
                    <Chip
                      key={`funnel-${id}`}
                      label={`Funnel: ${funnel?.name || id}`}
                      onDelete={() => {
                        const newFunnels = pendingFilters.funnelId.filter(fId => fId !== id);
                        handleFilterChange('funnelId', newFunnels);
                      }}
                      size="small"
                      sx={{ mt: 1 }}
                    />
                  );
                })}
                
                {/* Date Chips */}
                {pendingFilters.startDate && (
                  <Chip
                    key="startDate"
                    label={`Start: ${pendingFilters.startDate instanceof Date ? 
                      pendingFilters.startDate.toLocaleDateString() : 
                      new Date(pendingFilters.startDate).toLocaleDateString()}`}
                    onDelete={() => handleFilterChange('startDate', null)}
                    size="small"
                    sx={{ mt: 1 }}
                  />
                )}

                {pendingFilters.endDate && (
                  <Chip
                    key="endDate"
                    label={`End: ${pendingFilters.endDate instanceof Date ? 
                      pendingFilters.endDate.toLocaleDateString() : 
                      new Date(pendingFilters.endDate).toLocaleDateString()}`}
                    onDelete={() => handleFilterChange('endDate', null)}
                    size="small"
                    sx={{ mt: 1 }}
                  />
                )}
              </Stack>
            </Box>
          )}

          {/* Add Apply Filters and Cancel buttons at the bottom */}
          <Box mt={3} display="flex" gap={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleApplyFilters}
              fullWidth
            >
              Apply Filters
            </Button>
            
            {activeFilterCount > 0 && (
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClearFilters}
                startIcon={<ClearIcon />}
                fullWidth
              >
                Clear
              </Button>
            )}
          </Box>
        </Box>
      </Drawer>

      {/* Overview Cards */}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={2}>
          <Card>
            <CardContent>
              <Avatar sx={{ bgcolor: theme.palette.primary.main, mb: 1 }}>
                <GroupIcon />
              </Avatar>
              <Typography variant="h6">Total Leads</Typography>
              <Typography variant="h4">{total_leads}</Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Repeat similar blocks for other overview cards */}
        <Grid item xs={12} sm={6} md={2}>
          <Card>
            <CardContent>
              <Avatar sx={{ bgcolor: theme.palette.success.main, mb: 1 }}>
                <TrendingUpIcon />
              </Avatar>
              <Typography variant="h6">Leads Converted</Typography>
              <Typography variant="h4">{leads_converted}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Card>
            <CardContent>
              <Avatar sx={{ bgcolor: theme.palette.info.main, mb: 1 }}>
                <CampaignIcon />
              </Avatar>
              <Typography variant="h6">Active Campaigns</Typography>
              <Typography variant="h4">{active_campaigns}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Card>
            <CardContent>
              <Avatar sx={{ bgcolor: theme.palette.warning.main, mb: 1 }}>
                <MessageIcon />
              </Avatar>
              <Typography variant="h6">Interactions Today</Typography>
              <Typography variant="h4">{interactions_today}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Card>
            <CardContent>
              <Avatar sx={{ bgcolor: theme.palette.secondary.main, mb: 1 }}>
                <SentimentSatisfiedIcon />
              </Avatar>
              <Typography variant="h6">Sentiment Score</Typography>
              <Typography variant="h4">{sentiment_score}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Tabs for Sections */}
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 4 }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="Dashboard Sections">
          <Tab label="Leads Management" />
          <Tab label="Interactions" />
          <Tab label="Campaigns" />
          <Tab label="Tasks & Calendar" />
          <Tab label="Top Performers" />
        </Tabs>
      </Box>

      {/* Tab Panels */}
      <TabPanel value={tabValue} index={0}>
        {/* Leads Management Components */}
        {/* High Priority Leads Visualization */}
        <Box mt={4}>
          <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
            High-Priority Leads
          </Typography>
          {top_high_priority_leads.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Score</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {top_high_priority_leads.map((lead) => {
                const { backgroundColor, textColor } = getPriorityColor(lead.score);
                return (
                  <TableRow
                    key={lead.id}
                    sx={{ backgroundColor }}
                  >
                    <TableCell sx={{ color: textColor }}>{lead.name}</TableCell>
                    <TableCell sx={{ color: textColor }}>{lead.score.toFixed(2)}</TableCell>
                  </TableRow>
                );
              })}
              </TableBody>
            </Table>
          ) : (
            <Typography sx={{ color: theme.palette.text.primary }}>No high-priority leads available.</Typography>
          )}
        </Box>

        {/* Conversion Probability Visualization */}
        <Box mt={4}>
          <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
            Leads with High Conversion Probability
          </Typography>
          {top_conversion_leads.length ? (
            top_conversion_leads.map((lead) => (
              <Card key={lead.id} sx={{ mb: 2 }}>
                <CardContent>
                  <Typography variant="subtitle1">{lead.name}</Typography>
                  <Typography variant="body2">
                    Conversion Probability: {lead.conversion_probability.toFixed(2)}%
                  </Typography>
                </CardContent>
              </Card>
            ))
          ) : (
            <Typography sx={{ color: theme.palette.text.primary }}>No data available.</Typography>
          )}
        </Box>

        {/* Pipeline Velocity Visualization */}
        <Box mt={4}>
          <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
            Pipeline Velocity Metrics
          </Typography>
          {pipeline_velocity.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Stage</TableCell>
                  <TableCell>Average Time (Hours)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pipeline_velocity.map((item) => (
                  <TableRow key={item.stage}>
                    <TableCell>{item.stage}</TableCell>
                    <TableCell>{item.average_time_hours.toFixed(2)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Typography sx={{ color: theme.palette.text.primary }}>No pipeline velocity data available.</Typography>
          )}
        </Box>

        {/* Leads Funnel Visualization */}
        <Box mt={4}>
          <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
            Leads Funnel
          </Typography>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart
              data={leads_funnel.length ? leads_funnel : sampleFunnelData}
            >
              <XAxis dataKey="stage" tick={{ fill: theme.palette.text.primary }} />
              <YAxis tick={{ fill: theme.palette.text.primary }} />
              
              <Tooltip
                contentStyle={{
                  backgroundColor: theme.palette.background.paper,
                  borderColor: theme.palette.divider,
                }}
                itemStyle={{ color: theme.palette.text.primary }}
                labelStyle={{ color: theme.palette.text.primary }}
              />
              <Line
                type="monotone"
                dataKey="count"
                stroke={theme.palette.primary.main}
              />
            </LineChart>
          </ResponsiveContainer>
        </Box>

        {/* Sales Pipeline */}
        <Box mt={4}>
          <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
            Sales Pipeline
          </Typography>
          <ResponsiveContainer width="100%" height={400}>
            <FunnelChart>
              <Tooltip
                contentStyle={{
                  backgroundColor: theme.palette.background.paper,
                  borderColor: theme.palette.divider,
                }}
                itemStyle={{ color: theme.palette.text.primary }}
                labelStyle={{ color: theme.palette.text.primary }}
              />
              <Funnel
                dataKey="value"
                data={funnelData}
                isAnimationActive
                onClick={handleFunnelClick}
              >
                <LabelList position="right" fill={theme.palette.text.primary} stroke="none" dataKey="name" />
              </Funnel>
            </FunnelChart>
          </ResponsiveContainer>
        </Box>
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        {/* Interactions Components */}
        {/* Sentiment Analysis Overview */}
        <Box mt={4}>
          <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
            Sentiment Analysis
          </Typography>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={
                  sentiment_analysis.length
                    ? sentiment_analysis
                    : sampleSentimentData
                }
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={100}
                labelLine={false}
                label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                labelStyle={{ fill: theme.palette.text.primary }}
              >
                {(sentiment_analysis.length
                  ? sentiment_analysis
                  : sampleSentimentData
                ).map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip
                contentStyle={{
                  backgroundColor: theme.palette.background.paper,
                  borderColor: theme.palette.divider,
                }}
                itemStyle={{ color: theme.palette.text.primary }}
                labelStyle={{ color: theme.palette.text.primary }}
              />
            </PieChart>
          </ResponsiveContainer>
        </Box>

        {/* Activity Feed */}
        <Box mt={4}>
          <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
            Activity Feed
          </Typography>
          {activity_feed.length ? (
            activity_feed.map((activity, index) => (
              <Card key={index} sx={{ mb: 2 }}>
                <CardContent>
                  <Typography variant="body2">
                    {activity.user} {activity.action} on{' '}
                    {new Date(activity.timestamp).toLocaleString()}
                  </Typography>
                </CardContent>
              </Card>
            ))
          ) : (
            <Typography>No recent activities.</Typography>
          )}
        </Box>
        {/* Recent Interactions */}
        <Box mt={4}>
          <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
            Recent Interactions
          </Typography>
          {recent_interactions.length ? (
            recent_interactions.map((interaction) => (
              <Card key={interaction.id} sx={{ mb: 2 }}>
                <CardContent>
                  <Typography variant="subtitle1">
                    {interaction.interaction_type} with{' '}
                    {interaction.lead.first_name} {interaction.lead.last_name}
                  </Typography>
                  <Typography variant="body2">
                    {interaction.description}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {new Date(interaction.timestamp).toLocaleString()}
                  </Typography>
                </CardContent>
              </Card>
            ))
          ) : (
            <Typography sx={{ color: theme.palette.text.primary }}>No recent interactions available.</Typography>
          )}
        </Box>
      </TabPanel>

      <TabPanel value={tabValue} index={2}>
        {/* Campaign Performance Components */}
        {/* Active Campaigns (detailed view) */}
        {/* Campaign Performance Metrics */}
      </TabPanel>

      <TabPanel value={tabValue} index={3}>
        {/* Tasks & Calendar Components */}
        {/* Calendar Integration */}
        <Box mt={4}>
          <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
            Calendar
          </Typography>
          <Calendar
            localizer={localizer}
            events={calendarEvents}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 500 }}
          />
        </Box>
        {/* Upcoming Tasks/Reminders */}
        <Box mt={4}>
          <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
            Upcoming Tasks
          </Typography>
          {upcoming_tasks.length ? (
            upcoming_tasks.map((task) => (
              <Card key={task.id} sx={{ mb: 2 }}>
                <CardContent>
                  <Typography variant="subtitle1">{task.title}</Typography>
                  <Typography variant="body2">
                    Due: {new Date(task.due_date).toLocaleDateString()}
                  </Typography>
                  <Typography variant="body2">Priority: {task.priority}</Typography>
                </CardContent>
              </Card>
            ))
          ) : (
            <Typography sx={{ color: theme.palette.text.primary }}>No upcoming tasks.</Typography>
          )}
        </Box>
      </TabPanel>

      <TabPanel value={tabValue} index={4}>
        {/* Top Performers Components */}
        {/* Top Performing Leads/Campaigns */}
        <Box mt={4}>
          <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
            Top Performers
          </Typography>
          {top_performers.length ? (
            top_performers.map((item) => (
              <Card key={item.id} sx={{ mb: 2 }}>
                <CardContent>
                  <Typography variant="subtitle1">{item.name}</Typography>
                  <Typography variant="body2">
                    Engagement Score: {item.engagement_score}
                  </Typography>
                  <Typography variant="body2">
                    Conversion Rate: {item.conversion_rate}%
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    Last Interaction:{' '}
                    {new Date(item.last_interaction_date).toLocaleDateString()}
                  </Typography>
                </CardContent>
              </Card>
            ))
          ) : (
            <Typography>No top performers available.</Typography>
          )}
        </Box>
      </TabPanel>
    </Box>
  );
};

export default Dashboard;
