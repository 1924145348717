// src/components/NotificationsPopover.js

import React, { useEffect } from 'react';
import {
  Popover,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemSecondaryAction,
  Typography,
  Box,
  Button,
  Divider,
  CircularProgress,
  Badge,
  IconButton,
  Tooltip,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useSelector, useDispatch } from 'react-redux';
import { markNotificationAsRead, fetchUnreadNotifications } from '../redux/notificationSlice';
import { useNavigate } from 'react-router-dom';
import { showSnackbar } from '../redux/snackbarSlice';

const NotificationsPopover = ({ anchorEl, open, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const unreadNotifications = useSelector((state) => 
    state.notifications.items.filter(notification => !notification.is_read)
  );
  const loading = useSelector((state) => state.notifications.loading);

  // Fetch unread notifications when popover opens
  useEffect(() => {
    if (open) {
      dispatch(fetchUnreadNotifications());
    }
  }, [open, dispatch]);

  const handleMarkAsRead = async (event, notification) => {
    event.stopPropagation(); // Prevent any parent click handlers
    try {
      await dispatch(markNotificationAsRead(notification.id)).unwrap();
      dispatch(showSnackbar({
        message: 'Notification marked as read',
        severity: 'success'
      }));
      // Refresh the unread notifications list
      dispatch(fetchUnreadNotifications());
    } catch (error) {
      dispatch(showSnackbar({
        message: 'Failed to mark notification as read',
        severity: 'error'
      }));
    }
  };

  const handleNotificationClick = (notification) => {
    // Navigate to relevant page or show details
    navigate('/crm/notifications');
    onClose();
  };

  return (
    <Popover
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        sx: {
          width: 300,
          maxHeight: '80vh',
        },
      }}
    >
      <Box p={2} display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="h6">Notifications</Typography>
          {unreadNotifications.length > 0 && (
            <Badge
              badgeContent={unreadNotifications.length}
              color="primary"
              sx={{ ml: 1 }}
            />
          )}
        </Box>
        <Button 
          size="small" 
          onClick={() => navigate('/crm/notifications')}
          color="primary"
        >
          View All
        </Button>
      </Box>
      <Divider />
      <List
        sx={{
          maxHeight: 400,
          overflowY: 'auto',
          p: 0,
        }}
      >
        {loading ? (
          <Box display="flex" justifyContent="center" p={2}>
            <CircularProgress size={24} />
          </Box>
        ) : unreadNotifications.length === 0 ? (
          <ListItem>
            <ListItemText 
              primary="No unread notifications"
              primaryTypographyProps={{
                color: 'text.secondary',
                align: 'center'
              }}
            />
          </ListItem>
        ) : (
          unreadNotifications.map((notification) => (
            <ListItem 
              key={notification.id}
              sx={{
                borderBottom: '1px solid',
                borderColor: 'divider',
                '&:hover': {
                  backgroundColor: 'action.hover',
                },
                pr: 7, // Add padding for the action button
                cursor: 'default',
              }}
            >
              <ListItemText
                primary={notification.message}
                secondary={new Date(notification.created_at).toLocaleString()}
                primaryTypographyProps={{
                  style: { 
                    fontWeight: 'bold',
                    color: notification.is_high_priority ? 'error.main' : 'inherit'
                  }
                }}
                secondaryTypographyProps={{
                  style: { 
                    fontSize: '0.75rem',
                    color: 'text.secondary'
                  }
                }}
              />
              <ListItemSecondaryAction>
                <Tooltip title="Mark as read">
                  <IconButton 
                    edge="end" 
                    size="small"
                    onClick={(e) => handleMarkAsRead(e, notification)}
                    sx={{
                      '&:hover': {
                        color: 'primary.main',
                      },
                    }}
                  >
                    <CheckCircleOutlineIcon />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          ))
        )}
      </List>
    </Popover>
  );
};

export default NotificationsPopover;
