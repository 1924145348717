// src/components/AgentDashboard.jsx
import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import { api } from '../services/authService';
import CampaignList from './CampaignList';
import AgentConfiguration from './AgentConfiguration';

export default function AgentDashboard() {
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [isConfiguring, setIsConfiguring] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchCampaigns();
  }, []);

  const fetchCampaigns = async () => {
    try {
      const response = await api.get('/crm/campaigns/');
      setCampaigns(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
      setLoading(false);
    }
  };

  const handleSaveAgent = async (agentData) => {
    try {
      await api.post('/crm/agents/', agentData);
      setIsConfiguring(false);
      // Refresh campaign data
      fetchCampaigns();
    } catch (error) {
      console.error('Error saving agent:', error);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box display="flex" minHeight="100vh">
      <Box width={250} borderRight="1px solid" borderColor="divider" p={2}>
        <CampaignList
          campaigns={campaigns}
          onSelect={setSelectedCampaign}
          selectedCampaign={selectedCampaign}
        />
      </Box>

      <Box flex={1} p={3}>
        {selectedCampaign ? (
          <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
              <Typography variant="h5">
                {selectedCampaign.name}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setIsConfiguring(true)}
              >
                Add Agent
              </Button>
            </Box>
            
            {isConfiguring && (
              <AgentConfiguration
                campaign={selectedCampaign}
                onSave={handleSaveAgent}
                onCancel={() => setIsConfiguring(false)}
              />
            )}
          </Box>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <Typography variant="h6" color="textSecondary">
              Select a campaign to get started
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}