import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './redux/store'; // This matches your store file exports
import { AuthProvider } from './contexts/AuthContext';
import { LocalizationProvider } from '@mui/x-date-pickers'; // Import LocalizationProvider
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'; // Use Moment.js adapter
import Layout from './components/Layout';
import Dashboard from './pages/Dashboard';
import LeadsList from './pages/LeadsList';
import LeadDetails from './pages/LeadDetails';
import Accounts from './pages/Accounts';
import AccountSettings from './pages/AccountSettings';
import Funnels from './pages/Funnels';
import FunnelSettings from './pages/FunnelSettings';
import Campaigns from './pages/Campaigns';
import CampaignSettings from './pages/CampaignSettings';
import Profile from './pages/Profile';
import Login from './pages/Login';
import Signup from './pages/Signup';
import ToDoPage from './pages/ToDoPage';
import IntegrationsPage from './pages/IntegrationsPage';
import ProtectedRoute from './components/ProtectedRoute';
import { useDispatch, useSelector } from 'react-redux';
import { setCredentials, selectCurrentToken } from './redux/authSlice';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import ActivateAccount from './pages/ActivateAccount'; 
import SetPassword from './pages/SetPassword'; 
import NotificationsPage from './pages/NotificationsPage';
import NotificationRulesPage from './pages/NotificationRulesPage';
import useNotificationPolling from './hooks/useNotificationPolling';
import CustomSnackbar from './components/CustomSnackbar';
import AgentDashboard from './components/AgentDashboard';

const theme = createTheme();

function AppContent() {
  const dispatch = useDispatch();
  const token = useSelector(selectCurrentToken);
  const isAuthenticated = !!token;

  useEffect(() => {
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refreshToken');
    if (token && refreshToken) {
      dispatch(setCredentials({ token, refreshToken }));
    }
  }, [dispatch]);

  useNotificationPolling();

  return (
    <Router>
      <Layout>
        <CustomSnackbar />
        <Routes>
          {/* Auth Routes */}
          <Route path="/activate/:uid/:token" element={<ActivateAccount />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/set-password" element={<SetPassword />} />
          
          {/* CRM Routes (Protected Routes) */}
          <Route path="/crm/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route path="/crm/leads" element={<ProtectedRoute><LeadsList /></ProtectedRoute>} /> 
          <Route path="/crm/leads/:leadId/details" element={<ProtectedRoute><LeadDetails /></ProtectedRoute>} />
          <Route path="/crm/funnels" element={<ProtectedRoute><Funnels /></ProtectedRoute>} />
          <Route path="/crm/funnel/:funnelId" element={<ProtectedRoute><FunnelSettings /></ProtectedRoute>} /> 
          <Route path="/crm/campaigns" element={<ProtectedRoute><Campaigns /></ProtectedRoute>} />
          <Route path="/crm/campaign/:campaignId" element={<ProtectedRoute><CampaignSettings /></ProtectedRoute>} />         
          <Route path="/crm/accounts" element={<ProtectedRoute><Accounts /></ProtectedRoute>} />
          <Route path="/crm/account/:accountId" element={<ProtectedRoute><AccountSettings /></ProtectedRoute>} />
          <Route path="/crm/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
          <Route path="/crm/tasks" element={<ProtectedRoute><ToDoPage /></ProtectedRoute>} />
          <Route path="/crm/notifications" element={<ProtectedRoute><NotificationsPage /></ProtectedRoute>} />
          <Route path="/crm/notification-rules" element={<ProtectedRoute><NotificationRulesPage /></ProtectedRoute>} />
          <Route path="/" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />

          {/* Integrations Route */}
          <Route
            path="/crm/integrations"
            element={
              <ProtectedRoute>
                <IntegrationsPage />
              </ProtectedRoute>
            }
          />
          <Route path="/crm/agent-dashboard" element={<ProtectedRoute><AgentDashboard /></ProtectedRoute>} />

          {/* Default route (usually a 404 page or redirect to home) */}
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </Layout>
    </Router>
  );
}

function App() {
  return (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <AppContent />
            </LocalizationProvider>
          </ThemeProvider>
        </AuthProvider>
      </PersistGate>
    </ReduxProvider>
  );
}

export default App;