// src/pages/LeadDetails.js
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import {
  Box, Typography, Grid, Paper, Button, Stack, CircularProgress, Table, TableBody, TableCell, TableContainer, 
  TableHead, TableRow, TextField, MenuItem, Select, FormControl, InputLabel, RadioGroup, FormControlLabel, Radio,
  Divider, Tabs, Tab, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Card, CardContent, CardActions, 
  Snackbar, Alert, IconButton
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import ClearIcon from '@mui/icons-material/Clear';
import CreateScheduledReachOutDialog from '../components/CreateScheduledReachOutDialog';
import CreateTaskDialog from '../components/CreateTaskDialog';
import ConfirmDeleteDialog from '../components/ConfirmDeleteDialog';

import { api } from '../services/authService';

const LeadDetails = () => {
  const { leadId } = useParams();
  const [lead, setLead] = useState(null);
  const [campaignId, setCampaignId] = useState(null);
  const [intakeSections, setIntakeSections] = useState([]); // New state for intake sections
  const [intakeValues, setIntakeValues] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingIntake, setLoadingIntake] = useState(true); // Separate loading for intake sections
  const [saving, setSaving] = useState(false);
  const [activeTab, setActiveTab] = useState(0); // New state for tab selection
  
  const [notes, setNotes] = useState([]); // State for notes
  const [newNote, setNewNote] = useState(''); // State for new note input
  const [openNoteDialog, setOpenNoteDialog] = useState(false); // State for dialog visibility
  const [editNoteId, setEditNoteId] = useState(null);
  const [editNoteContent, setEditNoteContent] = useState('');
  const [deleteNoteId, setDeleteNoteId] = useState(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // 'success' or 'error'

  // Edit mode and form fields for lead profile
  const [isEditing, setIsEditing] = useState(false);
  const [formValues, setFormValues] = useState({
    first_name: '',
    last_name: '',
    phone_number: '',
    email: '',
    date_of_birth: '',
    age: ''
  });

  // Validation state for phone number and email
  const [errors, setErrors] = useState({
    phone_number: '',
    email: ''
  });

  // Add state inside your LeadDetails component
  const [openScheduleDialog, setOpenScheduleDialog] = useState(false);
  const [openTaskDialog, setOpenTaskDialog] = useState(false);
  useEffect(() => {
    fetchLeadDetails();
    fetchLeadNotes();
    fetchIntakeValues();
  }, [leadId]);

  // Separate function to fetch lead details
  const fetchLeadDetails = async () => {
    try {
      setLoading(true);
      const leadResponse = await api.get(`/crm/leads/${leadId}/details/`);
      setLead(leadResponse.data);

      setFormValues({
        first_name: leadResponse.data.first_name || '',
        last_name: leadResponse.data.last_name || '',
        phone_number: leadResponse.data.phone_number || '',
        email: leadResponse.data.email || '',
        date_of_birth: leadResponse.data.date_of_birth || '',
        age: leadResponse.data.age || ''
      });

      fetchIntakeSections(leadResponse.data.campaign); // Pass campaign ID to intake section call
      setCampaignId(leadResponse.data.campaign);
    } catch (error) {
      console.error('Error fetching lead details:', error);
    } finally {
      setLoading(false);
    }
  };

  // Separate function to fetch intake sections based on campaign ID
  const fetchIntakeSections = async (campaignId) => {
    try {
      setLoadingIntake(true);
      const intakeResponse = await api.get(`/crm/intake_sections/?campaign_id=${campaignId.id}`);
      setIntakeSections(intakeResponse.data); // Set intake sections
    } catch (error) {
      console.error('Error fetching intake sections:', error);
    } finally {
      setLoadingIntake(false);
    }
  };

  // Fetch notes for the lead
  const fetchLeadNotes = async () => {
      try {
        const notesResponse = await api.get(`/crm/leads/${leadId}/notes/`);
        setNotes(notesResponse.data);
      } catch (error) {
        console.error('Error fetching notes:', error);
      }
    };

    // Open note dialog
    const handleOpenNoteDialog = () => {
      setOpenNoteDialog(true);
    };

    // Close note dialog
    const handleCloseNoteDialog = () => {
      setOpenNoteDialog(false);
      setNewNote(''); // Clear the note input when dialog closes
    };  

  // Save a new note
  const handleAddNote = async () => {
      try {
        const response = await api.post(`/crm/leadnotes/`, {
          lead: leadId,
          note: newNote,
        });
        setNotes((prevNotes) => [...prevNotes, response.data]); // Add new note
        handleCloseNoteDialog();  // Close dialog
        setSnackbarMessage('Note added successfully');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } catch (error) {
        console.error('Error saving note:', error);
        setSnackbarMessage('Failed to save note. Please try again.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    };

  const handleEditNote = (noteId, noteContent) => {
      setEditNoteId(noteId);
      setEditNoteContent(noteContent);
  };

  const handleSaveNoteEdit = async (noteId) => {
      try {
          await api.put(`/crm/leadnotes/${noteId}/`, { note: editNoteContent });
          setEditNoteId(null);
          fetchLeadNotes(); // Refresh notes after saving
      } catch (error) {
          console.error('Error updating note:', error);
      }
  };

  const handleDeleteNote = (noteId) => {
    setDeleteNoteId(noteId);
    setConfirmDeleteOpen(true);
  };
  
  const handleConfirmDelete = async () => {
    try {
      await api.delete(`/crm/leadnotes/${deleteNoteId}/`);
      setNotes((prevNotes) => prevNotes.filter(note => note.id !== deleteNoteId));
      setSnackbarMessage('Note deleted successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error deleting note:', error);
      setSnackbarMessage('Failed to delete note. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setConfirmDeleteOpen(false);
      setDeleteNoteId(null);
    }
  };
  
  // Handle intake field changes
  const handleIntakeFieldChange = (fieldId, newValue) => {
      setIntakeValues((prevValues) => ({
      ...prevValues,
      [fieldId]: newValue,
      }));
  };

  const fetchIntakeValues = async () => {
    try {
      const response = await api.get(`/crm/leadintakevalues/?lead_id=${leadId}`);
      const intakeValuesData = response.data;
      // Transform the data into a suitable format
      const values = {};
      intakeValuesData.forEach(item => {
        values[item.intake_field] = item.value; // Use intake_field ID as key
      });
      setIntakeValues(values);
    } catch (error) {
      console.error('Error fetching intake values:', error);
    }
  };

  // Save changes to intake fields
  const handleSaveIntake = async () => {
    try {
      setSaving(true);
      await api.put('/crm/leadintakevalues/bulk_update/', {
        lead_id: leadId,
        intake_values: Object.entries(intakeValues).map(([intake_field_id, value]) => ({
          intake_field: parseInt(intake_field_id, 10), // Ensure it's an integer ID
          value,
        })),
      });
      setSnackbarMessage('Intake values saved successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error saving intake values:', error);
      setSnackbarMessage('Error saving intake values. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setSaving(false);
    }
  };    

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Export lead data when export button is clicked
  const handleExport = async () => {
    try {
      // Fetch the CSV file from the server
      const response = await api.post('/crm/lead_data/', {
        lead_id: leadId,  // Pass any additional filter params if necessary
      }, {
        responseType: 'blob'  // Important to handle binary data (CSV)
      });

      // Create a Blob from the CSV response
      const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `lead_${leadId}_data.csv`);  // Dynamic file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Show success message
      setSnackbarMessage('Data exported successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error exporting lead data:', error);
      setSnackbarMessage('Failed to export data.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  // Render each intake field based on its type
  const renderIntakeField = (field, value) => {
      const { field_type, name } = field;

      switch (field_type) {
      case 'text':
          return (
          <TextField
              fullWidth
              label={name}
              value={value || ''}
              onChange={(e) => handleIntakeFieldChange(field.id, e.target.value)}
              variant="outlined"
              margin="normal"
          />
          );
      case 'number':
          return (
          <TextField
              fullWidth
              label={name}
              value={value || ''}
              onChange={(e) => handleIntakeFieldChange(field.id, e.target.value)}
              type="number"
              variant="outlined"
              margin="normal"
          />
          );
        case 'dropdown':
          return (
            <Box position="relative">
              <IconButton 
                size="small"
                onClick={() => handleIntakeFieldChange(field.id, '')}
                sx={{ 
                  position: 'absolute',
                  right: 0,
                  top: -20,
                  visibility: value ? 'visible' : 'hidden'
                }}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
              <FormControl fullWidth margin="normal">
                <InputLabel>{name}</InputLabel>
                <Select
                  value={value || ''}
                  onChange={(e) => handleIntakeFieldChange(field.id, e.target.value)}
                  label={name}
                >
                  {field.dropdown_options.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          );
        case 'boolean':
          return (
            <Box position="relative">
              <IconButton 
                size="small"
                onClick={() => handleIntakeFieldChange(field.id, null)}
                sx={{ 
                  position: 'absolute',
                  right: 0,
                  top: -20,
                  // Show the clear button when value is either true or false
                  visibility: (value === true || value === false || value === null || value === undefined || value === '') ? 'visible' : 'hidden'
                }}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
              <FormControl fullWidth margin="normal">
                <InputLabel>{name}</InputLabel>
                <Select
                  value={value === null || value === undefined || value === '' ? 'false' : value.toString()}
                  onChange={(e) => {
                    const boolValue = e.target.value === 'true';
                    handleIntakeFieldChange(field.id, boolValue);
                  }}
                  label={name}
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
              </FormControl>
            </Box>
          );
      case 'date':
        return (
            <TextField
                fullWidth
                label={name}
                type="date"
                value={value || ''}
                onChange={(e) => handleIntakeFieldChange(field.id, e.target.value)}
                variant="outlined"
                margin="normal"
                InputLabelProps={{
                    shrink: true,
                }}
            />
        );
      default:
          return null;
      }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    // Reset form values to original lead data
    setFormValues({
      first_name: lead.first_name,
      last_name: lead.last_name,
      phone_number: lead.phone_number,
      email: lead.email,
      date_of_birth: lead.date_of_birth,
      age: lead.age
    });
  };

  const phoneNumberRegex = /^\d{3}-\d{3}-\d{4}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleSaveClick = async () => {
    // Ensure the phone number and email are valid before saving
    if (errors.phone_number || errors.email) {
      setSnackbarMessage('Please fix the errors in the form before saving.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return; // Prevent saving if there are validation errors
    }

    try {
      // Prepare the payload with the required fields
      const updatedData = {
        first_name: formValues.first_name,
        last_name: formValues.last_name,
        phone_number: formValues.phone_number,
        email: formValues.email,
        campaign: campaignId, // Ensure the campaign ID is included
        date_of_birth: formValues.date_of_birth ? formValues.date_of_birth : null, // Handle nullable date of birth
        age: formValues.age ? parseInt(formValues.age, 10) : null // Ensure age is a valid integer or null
      };

      await api.put(`/crm/leads/${leadId}/`, updatedData);
      setLead((prevLead) => ({
        ...prevLead,
        ...updatedData
      }));
      setIsEditing(false);
      setSnackbarMessage('Profile updated successfully!');
      setSnackbarSeverity('success');
    } catch (error) {
      console.error('Error updating lead profile:', error);
      setSnackbarMessage('Error updating profile. Please try again.');
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));

    // Validate phone number as the user types
    if (name === 'phone_number') {
      if (!phoneNumberRegex.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phone_number: 'Invalid phone number format. Use XXX-XXX-XXXX.'
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phone_number: ''
        }));
      }
    }

    // Validate email as the user types
    if (name === 'email') {
      if (!emailRegex.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: 'Invalid email format. Please enter a valid email address.'
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: ''
        }));
      }
    }
  };
  
  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!lead) {
    return <Typography variant="h6" color="error">Lead not found</Typography>;
  };

  const handleScheduledReachOutSuccess = (message) => {
    setSnackbarMessage(message);
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
  };
  
  const handleScheduledReachOutError = (message) => {
    setSnackbarMessage(message);
    setSnackbarSeverity('error');
    setSnackbarOpen(true);
  };

  const handleTaskSuccess = (message) => {
    setSnackbarMessage(message);
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
  };
  
  const handleTaskError = (message) => {
    setSnackbarMessage(message);
    setSnackbarSeverity('error');
    setSnackbarOpen(true);
  };

  return (
    <Box p={3}>
      <Grid container spacing={3}>
        {/* Row with Back to Leads and Start/Go to Intake Buttons */}
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            {/* Back to Leads Button */}
            <Link to="/crm/leads" style={{ textDecoration: 'none' }}>
              <Button variant="contained" startIcon={<ArrowBackIcon />}>
                Back to Leads
              </Button>
            </Link>
          </Box>
        </Grid>
        
        {/* Lead Profile Section */}
        <Grid item xs={12} md={4}>
            <Paper elevation={3} sx={{ p: 3 }}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h5">Lead Profile</Typography>
                {!isEditing ? (
                  <IconButton onClick={handleEditClick}>
                    <EditIcon />
                  </IconButton>
                ) : (
                  <Box>
                    <IconButton onClick={handleSaveClick} color="primary">
                      <SaveIcon />
                    </IconButton>
                    <IconButton onClick={handleCancelClick} color="secondary">
                      <CancelIcon />
                    </IconButton>
                  </Box>
                )}
              </Box>
              <Divider sx={{ mt: 2, mb: 2 }} />

              {!isEditing ? (
                <>
                  <Typography>First Name: {lead.first_name}</Typography>
                  <Typography>Last Name: {lead.last_name}</Typography>
                  <Typography>Phone Number: {lead.phone_number || 'N/A'}</Typography>
                  <Typography>Email Address: {lead.email || 'N/A'}</Typography>
                  <Typography>Date of Birth: {lead.date_of_birth || 'N/A'}</Typography>
                  <Typography>Age: {lead.age || 'N/A'}</Typography>
                </>
              ) : (
                <>
                  <TextField
                    fullWidth
                    label="First Name"
                    name="first_name"
                    value={formValues.first_name}
                    onChange={handleInputChange}
                    margin="normal"
                  />
                  <TextField
                    fullWidth
                    label="Last Name"
                    name="last_name"
                    value={formValues.last_name}
                    onChange={handleInputChange}
                    margin="normal"
                  />
                  <TextField
                    fullWidth
                    label="Phone Number"
                    name="phone_number"
                    value={formValues.phone_number}
                    onChange={handleInputChange}
                    error={!!errors.phone_number}
                    helperText={errors.phone_number}
                    margin="normal"
                  />
                  <TextField
                    fullWidth
                    label="Email Address"
                    name="email"
                    value={formValues.email}
                    onChange={handleInputChange}
                    error={!!errors.email}
                    helperText={errors.email}
                    margin="normal"
                  />
                  <TextField
                    fullWidth
                    label="Date of Birth"
                    name="date_of_birth"
                    type="date"
                    value={formValues.date_of_birth}
                    onChange={handleInputChange}
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    fullWidth
                    label="Age"
                    name="age"
                    type="number"
                    value={formValues.age}
                    onChange={handleInputChange}
                    margin="normal"
                  />
                </>
              )}
            </Paper>
          </Grid>

        {/* General Overview Section */}
        <Grid item xs={12} md={8}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h6">Overview</Typography>
              <Box>
              <Button variant="contained" onClick={() => setOpenTaskDialog(true)} sx={{ mr: 2 }}>Create Task</Button>
                <Button variant="contained" onClick={() => setOpenScheduleDialog(true)}>Schedule Appointment</Button>
                <Button variant="contained" onClick={handleExport} sx={{ ml: 2 }}>Export</Button>
              </Box>
            </Box>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={6}>
                <Typography>Lead Creation Date: {new Date(lead.created_at).toLocaleString()}</Typography>
                <Typography>Contact Status: {lead.status?.name || 'N/A'}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>Last Contacted: --</Typography>
                <Typography>Funnel Stage: {lead.current_step?.name || 'N/A'}</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Add some margin between Lead Profile and Intake/Interactions sections */}
        <Grid item xs={12} sx={{ mt: 3 }} /> {/* Spacer Grid */}

        {/* Intake and Interactions Sections side by side */}
        <Grid container spacing={3} sx={{ alignItems: 'flex-start' }}>
          {/* Intake Section: 2/3 of the width */}
          <Grid item xs={8}>
            <Paper elevation={3} sx={{ p: 3 }}>
              <Typography variant="h6">Intake</Typography>
              <Divider sx={{ mt: 2, mb: 2 }} />
              {loadingIntake ? (
                <CircularProgress />
              ) : intakeSections.length > 0 ? (
                intakeSections.map((section, sectionIndex) => (
                  <Box key={sectionIndex} sx={{ mt: 3 }}>
                    <Typography variant="h6">{section.name}</Typography>
                    <Paper elevation={1} sx={{ p: 2, mt: 1 }}>
                      {section.intake_fields.map((field, fieldIndex) => {
                        const intakeValue = intakeValues[field.id] || '';
                        return (
                          <Box key={fieldIndex} sx={{ mt: 2 }}>
                            <Typography variant="body1" sx={{ mb: 1, fontWeight: 'bold' }}>
                              {field.intake_question}
                            </Typography>
                            {renderIntakeField(field, intakeValue)}
                          </Box>
                        );
                      })}
                    </Paper>
                  </Box>
                ))
              ) : (
                <Typography>No intake data available</Typography>
              )}
              <Button
                variant="contained"
                onClick={handleSaveIntake}
                disabled={saving}
                sx={{ mt: 3 }}
              >
                {saving ? 'Saving...' : 'Save Changes'}
              </Button>
            </Paper>
          </Grid>

          {/* Interactions Section: 1/3 of the width */}
          <Grid item xs={4}>
            <Paper elevation={3} sx={{ p: 3 }}>
              <Tabs value={activeTab} onChange={handleTabChange} aria-label="interactions tabs">
                <Tab label="Activity Log" />
                <Tab label="Notes" />
              </Tabs>
              <Divider sx={{ mt: 2, mb: 2 }} />
              {activeTab === 0 ? (
                // Activity Log Tab
                lead.interactions.length > 0 ? (
                  <TableContainer component={Paper} sx={{ mt: 2 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Type</TableCell>
                          <TableCell>Timestamp</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {lead.interactions.map((interaction, index) => (
                          <TableRow key={index}>
                            <TableCell>{interaction.interaction_type}</TableCell>
                            <TableCell>{new Date(interaction.timestamp).toLocaleString()}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Typography>No interactions available</Typography>
                )
              ) : (
               // Notes Tab
               <Box>
               <Button variant="contained" onClick={handleOpenNoteDialog} sx={{ mb: 2 }}>
                 Add Note
               </Button>
               {notes.length > 0 ? (
                 notes.map((note, index) => (
                   <Card key={index} sx={{ mb: 2 }}>
                     <CardContent>
                       <Typography variant="body2">
                         {note.user.first_name} {note.user.last_name} - {new Date(note.updated_at).toLocaleString()}
                       </Typography>
                       <Divider sx={{ my: 2 }} />
             
                       {/* Inline editing: show TextField if editing, otherwise display the note */}
                       {editNoteId === note.id ? (
                         <TextField
                           fullWidth
                           value={editNoteContent}
                           onChange={(e) => setEditNoteContent(e.target.value)}
                           multiline
                           rows={3}
                           variant="outlined"
                           sx={{ mt: 1 }}
                         />
                       ) : (
                         <Typography variant="body1" sx={{ mt: 1 }}>
                           {note.note}
                         </Typography>
                       )}
                     </CardContent>
                     <CardActions>
                       {editNoteId === note.id ? (
                         <Button
                           variant="contained"
                           color="primary"
                           startIcon={<SaveIcon />}
                           onClick={() => handleSaveNoteEdit(note.id, leadId)}
                         >
                           Save
                         </Button>
                       ) : (
                         <>
                           <EditIcon
                             sx={{ cursor: 'pointer', mr: 2 }}
                             onClick={() => handleEditNote(note.id, note.note)}
                           />
                           <DeleteIcon
                              sx={{ cursor: 'pointer', color: 'red' }}
                              onClick={() => handleDeleteNote(note.id)}
                           />
                         </>
                       )}
                     </CardActions>
                   </Card>
                 ))
               ) : (
                 <Typography>No notes available</Typography>
               )}
             </Box>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Grid>

      {/* Dialog for adding new note */}
        <Dialog open={openNoteDialog} onClose={handleCloseNoteDialog}>
        <DialogTitle>Add New Note</DialogTitle>
        <DialogContent>
            <DialogContentText>Enter the note for this lead.</DialogContentText>
            <TextField
            autoFocus
            fullWidth
            label="New Note"
            variant="outlined"
            multiline
            rows={4}
            value={newNote}
            onChange={(e) => setNewNote(e.target.value)}
            sx={{ mt: 2 }}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseNoteDialog}>Cancel</Button>
            <Button onClick={handleAddNote} variant="contained">Save</Button>
        </DialogActions>
        </Dialog>

        <ConfirmDeleteDialog
          open={confirmDeleteOpen}
          onClose={() => setConfirmDeleteOpen(false)}
          onConfirm={handleConfirmDelete}
          title="Delete Note"
          message="Are you sure you want to delete this note? This action cannot be undone."
        />

        {/* Add CreateTaskDialog component */}
        <CreateTaskDialog
          open={openTaskDialog}
          handleClose={() => setOpenTaskDialog(false)}
          accountId={lead.account}
          campaignId={campaignId.id}
          leadId={leadId}
          leadName={`${lead.first_name} ${lead.last_name}`}
          onSuccess={handleTaskSuccess}
          onError={handleTaskError}
        />

        {/* Scheduled Reachout Appointment */}
        <CreateScheduledReachOutDialog
          open={openScheduleDialog}
          handleClose={() => setOpenScheduleDialog(false)}
          leadId={leadId}
          campaignId={campaignId.id}
          leadName={`${lead.first_name} ${lead.last_name}`}
          onSuccess={handleScheduledReachOutSuccess}
          onError={handleScheduledReachOutError}
        />

        {/* Snackbar for feedback */}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
          <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
              {snackbarMessage}
          </Alert>
      </Snackbar>
    </Box>
    
  );
};

export default LeadDetails;
