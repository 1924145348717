// src/components/LeadFieldsConfiguration.js

import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Box,
  Paper,
  Grid,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  IconButton,
  ListItemSecondaryAction,
} from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { api } from '../services/authService';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';

const LeadFieldsConfiguration = ({
  campaignId,
  campaignModelId,
  selectedLeadFields,
  setSelectedLeadFields,
  accountId,
  handleSaveLeadFieldConfig,
}) => {
  const theme = useTheme();

  const [availableLeadFields, setAvailableLeadFields] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  // State variables for checkbox selections
  const [availableSelected, setAvailableSelected] = useState([]);
  const [selectedSelected, setSelectedSelected] = useState([]);

  // State for the add field dialog
  const [openAddFieldDialog, setOpenAddFieldDialog] = useState(false);
  const [newFieldData, setNewFieldData] = useState({
    name: '',
    field_type: '',
    required: false,
    dropdown_options: [],
  });
  const [openEditFieldDialog, setOpenEditFieldDialog] = useState(false);
  const [editFieldData, setEditFieldData] = useState({
    id: null,
    name: '',
    api_name: '',
    field_type: '',
    required: false,
    dropdown_options: [],
  });
  const [fieldErrors, setFieldErrors] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [newDropdownOption, setNewDropdownOption] = useState('');
  const [editIndex, setEditIndex] = useState(null);
  const [editingOption, setEditingOption] = useState('');

  // Fetch available lead fields based on campaign model
  useEffect(() => {
    if (campaignModelId) {
      fetchAvailableLeadFields();
    }
  }, [campaignModelId]);

  const fetchAvailableLeadFields = async () => {
    try {
      const response = await api.get(`/crm/campaign_models/${campaignModelId}/lead_fields/`, {
        params: {
          exclude_intake_fields: true,
          account_id: accountId,
          campaign_model_id: campaignModelId
        },
      });
      setAvailableLeadFields(response.data);
    } catch (error) {
      console.error('Error fetching lead fields:', error);
      showSnackbar('Error fetching lead fields', 'error');
    }
  };

  // Handle checkbox toggle in the available list
  const handleToggleAvailable = (apiName) => () => {
    const currentIndex = availableSelected.indexOf(apiName);
    const newSelected = [...availableSelected];

    if (currentIndex === -1) {
      newSelected.push(apiName);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setAvailableSelected(newSelected);
  };

  // Handle checkbox toggle in the selected list
  const handleToggleSelected = (apiName) => () => {
    const currentIndex = selectedSelected.indexOf(apiName);
    const newSelected = [...selectedSelected];

    if (currentIndex === -1) {
      newSelected.push(apiName);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setSelectedSelected(newSelected);
  };

  // Add selected fields to selectedLeadFields
  const handleAddFields = () => {
    const newFields = availableSelected.filter(
      (apiName) => !selectedLeadFields.includes(apiName)
    );
    if (newFields.length === 0) {
      showSnackbar('No fields selected to add.', 'info');
      return;
    }

    setSelectedLeadFields([...selectedLeadFields, ...newFields]);
    setAvailableSelected([]);
  };

  // Remove selected fields from selectedLeadFields
  const handleRemoveFields = () => {
    const fieldsToRemove = selectedSelected;
    if (fieldsToRemove.length === 0) {
      showSnackbar('No fields selected to remove.', 'info');
      return;
    }

    const updatedFields = selectedLeadFields.filter(
      (apiName) => !fieldsToRemove.includes(apiName)
    );
    setSelectedLeadFields(updatedFields);
    setSelectedSelected([]);
  };

  // Handler to open the add field dialog
  const handleOpenAddFieldDialog = () => {
    setNewFieldData({
      name: '',
      field_type: '',
      required: false,
      dropdown_options: [],
    });
    setFieldErrors({});
    setOpenAddFieldDialog(true);
  };

  // Handler to close the add field dialog
  const handleCloseAddFieldDialog = () => {
    setOpenAddFieldDialog(false);
  };

  // Handler for new field form input change
  const handleNewFieldChange = (event) => {
    const { name, value, checked } = event.target;
    
    setNewFieldData((prev) => {
      // If changing field type
      if (name === 'field_type') {
        return {
          ...prev,
          [name]: value,
          // Clear dropdown options only when changing field type away from dropdown
          dropdown_options: value === 'dropdown' ? (prev.dropdown_options || []) : []
        };
      }
      
      // For all other changes (including name, required, etc.)
      return {
        ...prev,
        [name]: name === 'required' ? checked : value
      };
    });
  };

  // Handler to submit the new field
  const handleAddNewField = async () => {
    // Basic validation
    const errors = {};
    if (!newFieldData.name) errors.name = 'Field name is required';
    if (!newFieldData.field_type) errors.field_type = 'Field type is required';
    
    // Validate dropdown options if field type is dropdown
    if (newFieldData.field_type === 'dropdown' && 
        (!newFieldData.dropdown_options || newFieldData.dropdown_options.length === 0)) {
      errors.dropdown_options = 'At least one dropdown option is required';
    }
  
    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }
  
    try {
      // Prepare the payload, including dropdown_options only if field type is dropdown
      const payload = {
        ...newFieldData,
        campaign_model_id: campaignModelId,
        account_id: accountId,
        // Only include dropdown_options if field type is dropdown
        dropdown_options: newFieldData.field_type === 'dropdown' 
          ? newFieldData.dropdown_options 
          : null
      };
  
      await api.post('/crm/lead_field_definitions/', payload);
      showSnackbar('New field added successfully', 'success');
      setOpenAddFieldDialog(false);
      // Reset any field errors
      setFieldErrors({});
      // Reset the form state
      setNewFieldData({
        name: '',
        field_type: 'text',
        required: false,
        dropdown_options: []
      });
      // Refresh the available lead fields
      fetchAvailableLeadFields();
    } catch (error) {
      console.error('Error adding new field:', error);
      // Handle specific validation errors from the backend if any
      if (error.response?.data) {
        const backendErrors = error.response.data;
        const formattedErrors = {};
        Object.keys(backendErrors).forEach(key => {
          formattedErrors[key] = backendErrors[key].join(' ');
        });
        setFieldErrors(formattedErrors);
      } else {
        showSnackbar('Failed to add new field', 'error');
      }
    }
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleOpenDeleteDialog = () => {
    if (availableSelected.length === 0) {
      showSnackbar('No fields selected to delete.', 'info');
      return;
    }
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleDeleteFields = async () => {
    const fieldIdsToDelete = availableSelected
      .map((apiName) => {
        const field = availableLeadFields.find((f) => f.api_name === apiName);
        return field ? field.id : null;
      })
      .filter((id) => id !== null);

    try {
      // Make API calls to delete each selected field
      await Promise.all(
        fieldIdsToDelete.map((leadFieldDefinitionId) =>
          api.delete(`/crm/lead_field_definitions/${leadFieldDefinitionId}/`)
        )
      );
      showSnackbar('Selected fields deleted successfully.', 'success');
      fetchAvailableLeadFields();
      // Remove deleted fields from selectedLeadFields
      setSelectedLeadFields(
        selectedLeadFields.filter((apiName) => !availableSelected.includes(apiName))
      );
      setAvailableSelected([]);
    } catch (error) {
      console.error('Error deleting lead fields:', error);
      showSnackbar('Failed to delete selected fields', 'error');
    }
    handleCloseDeleteDialog();
  };

  // Add handler to open edit dialog
  const handleOpenEditFieldDialog = (field) => {
    setEditFieldData({
      id: field.id,
      name: field.name,
      api_name: field.api_name,
      field_type: field.field_type,
      required: field.required,
      dropdown_options: field.dropdown_options || [],
    });
    setFieldErrors({});
    setOpenEditFieldDialog(true);
  };

  // Add handler to close edit dialog
  const handleCloseEditFieldDialog = () => {
    setOpenEditFieldDialog(false);
  };

  // Add handler for edit field form changes
  const handleEditFieldChange = (event) => {
    const { name, value, checked } = event.target;
    
    setEditFieldData((prev) => {
      // If changing field type
      if (name === 'field_type') {
        return {
          ...prev,
          [name]: value,
          // Clear dropdown options only when changing field type away from dropdown
          dropdown_options: value === 'dropdown' ? (prev.dropdown_options || []) : []
        };
      }
      
      // For all other changes (including name, required, etc.)
      return {
        ...prev,
        [name]: name === 'required' ? checked : value
      };
    });
  };

  // Add handler to submit field updates
  const handleUpdateField = async () => {
    // Basic validation
    const errors = {};
    if (!editFieldData.name) errors.name = 'Field name is required';
    if (!editFieldData.field_type) errors.field_type = 'Field type is required';
    
    // Validate dropdown options if field type is dropdown
    if (editFieldData.field_type === 'dropdown' && 
        (!editFieldData.dropdown_options || editFieldData.dropdown_options.length === 0)) {
      errors.dropdown_options = 'At least one dropdown option is required';
    }
  
    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }
  
    try {
      // Prepare the data, including dropdown_options only if field type is dropdown
      const updateData = {
        ...editFieldData,
        campaign_model_id: campaignModelId,
        account_id: accountId,
        // Only include dropdown_options if field type is dropdown
        dropdown_options: editFieldData.field_type === 'dropdown' 
          ? editFieldData.dropdown_options 
          : null
      };
  
      await api.put(`/crm/lead_field_definitions/${editFieldData.id}/`, updateData);
      showSnackbar('Field updated successfully', 'success');
      setOpenEditFieldDialog(false);
      // Reset any field errors
      setFieldErrors({});
      // Reset the edit form state
      setEditFieldData({
        name: '',
        api_name: '',
        field_type: 'text',
        required: false,
        dropdown_options: []
      });
      // Refresh the available lead fields
      fetchAvailableLeadFields();
    } catch (error) {
      console.error('Error updating field:', error);
      // Handle specific validation errors from the backend if any
      if (error.response?.data) {
        const backendErrors = error.response.data;
        const formattedErrors = {};
        Object.keys(backendErrors).forEach(key => {
          formattedErrors[key] = backendErrors[key].join(' ');
        });
        setFieldErrors(formattedErrors);
      } else {
        showSnackbar('Failed to update field', 'error');
      }
    }
  };

  const handleDragEnd = async (result) => {
    if (!result.destination) return;
  
    const items = Array.from(selectedLeadFields);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
  
    setSelectedLeadFields(items);
  
    // Get the field IDs and their new orders
    const fieldsToUpdate = items.map((apiName, index) => {
      const field = availableLeadFields.find(f => f.api_name === apiName);
      return {
        id: field.id,
        order: index,
        campaign_model_id: campaignModelId
      };
    });
  
    // Update the order in the backend
    try {
      await api.post('/crm/lead_field_definitions/reorder_fields/', {
        fields: fieldsToUpdate
      });
      showSnackbar('Field order updated successfully', 'success');
    } catch (error) {
      console.error('Error updating field order:', error);
      showSnackbar(
        error.response?.data?.error || 'Failed to update field order', 
        'error'
      );
      
      // Optionally revert the order if the API call fails
      // fetchAvailableLeadFields();
    }
  };

  const handleAddDropdownOption = () => {
    if (newDropdownOption.trim()) {
      const updatedOptions = [...(newFieldData.dropdown_options || []), newDropdownOption.trim()];
      setNewFieldData(prev => ({ ...prev, dropdown_options: updatedOptions }));
      setNewDropdownOption('');
    }
  };
  
  const handleRemoveDropdownOption = (index) => {
    const updatedOptions = newFieldData.dropdown_options.filter((_, i) => i !== index);
    setNewFieldData(prev => ({ ...prev, dropdown_options: updatedOptions }));
  };
  
  const startEditDropdownOption = (index) => {
    setEditIndex(index);
    setEditingOption(newFieldData.dropdown_options[index]);
  };
  
  const saveDropdownOption = () => {
    if (editingOption.trim()) {
      const updatedOptions = [...newFieldData.dropdown_options];
      updatedOptions[editIndex] = editingOption.trim();
      setNewFieldData(prev => ({ ...prev, dropdown_options: updatedOptions }));
      setEditIndex(null);
      setEditingOption('');
    }
  };
  
  const cancelEditDropdownOption = () => {
    setEditIndex(null);
    setEditingOption('');
  };

  const handleEditDropdownOption = () => {
    if (newDropdownOption.trim()) {
      const updatedOptions = [...(editFieldData.dropdown_options || []), newDropdownOption.trim()];
      setEditFieldData(prev => ({ ...prev, dropdown_options: updatedOptions }));
      setNewDropdownOption('');
    }
  };

  const handleRemoveEditDropdownOption = (index) => {
    const updatedOptions = editFieldData.dropdown_options.filter((_, i) => i !== index);
    setEditFieldData(prev => ({ ...prev, dropdown_options: updatedOptions }));
  };

  const startEditDropdownEditOption = (index) => {
    setEditIndex(index);
    setEditingOption(editFieldData.dropdown_options[index]);
  };

  const saveEditDropdownOption = () => {
    if (editingOption.trim()) {
      const updatedOptions = [...editFieldData.dropdown_options];
      updatedOptions[editIndex] = editingOption.trim();
      setEditFieldData(prev => ({ ...prev, dropdown_options: updatedOptions }));
      setEditIndex(null);
      setEditingOption('');
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h6" gutterBottom color="textPrimary">
        Configure Lead Fields
      </Typography>
      {/* Add a button to open the Add Field Dialog */}
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-start', padding: 2 }}>
        <Button variant="contained" onClick={handleOpenAddFieldDialog}>
          Add New Field
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleOpenDeleteDialog}
          sx={{ ml: 2 }}
          disabled={availableSelected.length === 0}
        >
          Delete Field
        </Button>
      </Box>
      <Grid container spacing={2}>
        {/* Available Lead Fields */}
        <Grid item xs={12} md={5}>
          <Paper sx={{ padding: 2, height: '400px', overflowY: 'auto' }}>
            <Typography variant="subtitle1">Available Lead Definitions</Typography>
            {availableLeadFields.length === 0 ? (
              <Typography variant="body2" sx={{ mt: 2 }}>
                No lead definitions in the system.
              </Typography>
            ) : (
              <List>
                {availableLeadFields
                  .filter((field) => !selectedLeadFields.includes(field.api_name))
                  .map((field) => {
                    const labelId = `available-list-label-${field.api_name}`;

                    return (
                      <ListItem
                        key={field.api_name}
                        secondaryAction={
                          <IconButton 
                            edge="end" 
                            onClick={() => handleOpenEditFieldDialog(field)}
                            size="small"
                          >
                            <EditIcon />
                          </IconButton>
                        }
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={availableSelected.includes(field.api_name)}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                            onClick={handleToggleAvailable(field.api_name)}
                          />
                        </ListItemIcon>
                        <ListItemText 
                          id={labelId} 
                          primary={field.name}
                          secondary={`Type: ${field.field_type}${field.required ? ' (Required)' : ''}`}
                        />
                      </ListItem>
                    );
                  })}
              </List>
            )}
          </Paper>
        </Grid>

        {/* Action Buttons */}
        <Grid
          item
          xs={12}
          md={2}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            variant="outlined"
            onClick={handleAddFields}
            disabled={availableSelected.length === 0}
            sx={{ mb: 2 }}
          >
            Add &gt;&gt;
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleRemoveFields}
            disabled={selectedSelected.length === 0}
          >
            &lt;&lt; Remove
          </Button>
        </Grid>

        {/* Selected Lead Fields */}
        <Grid item xs={12} md={5}>
          <Paper sx={{ padding: 2, height: '400px', overflowY: 'auto' }}>
            <Typography variant="subtitle1">Selected Lead Definitions</Typography>
            {selectedLeadFields.length === 0 ? (
              <Typography variant="body2" sx={{ mt: 2 }}>
                No lead definitions selected.
              </Typography>
            ) : (
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="selected-fields">
                  {(provided) => (
                    <List {...provided.droppableProps} ref={provided.innerRef}>
                      {selectedLeadFields.map((apiName, index) => {
                        const field = availableLeadFields.find((f) => f.api_name === apiName);
                        const labelId = `selected-list-label-${apiName}`;

                        return (
                          <Draggable key={apiName} draggableId={apiName} index={index}>
                            {(provided) => (
                              <ListItem
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                sx={{
                                  border: '1px solid rgba(0, 0, 0, 0.12)',
                                  borderRadius: 1,
                                  mb: 1,
                                  bgcolor: 'background.paper',
                                  cursor: 'grab',
                                  '&:active': { cursor: 'grabbing' },
                                  '&:hover': { bgcolor: 'action.hover' }
                                }}
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    edge="start"
                                    checked={selectedSelected.includes(apiName)}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                    onClick={handleToggleSelected(apiName)}
                                  />
                                </ListItemIcon>
                                <ListItemText 
                                  id={labelId} 
                                  primary={field ? field.name : apiName}
                                  secondary={field ? `Type: ${field.field_type}${field.required ? ' (Required)' : ''}` : ''}
                                />
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                  <IconButton 
                                    edge="end" 
                                    onClick={() => handleOpenEditFieldDialog(field)}
                                    size="small"
                                  >
                                    <EditIcon />
                                  </IconButton>
                                  <div {...provided.dragHandleProps}>
                                    <DragHandleIcon 
                                      color="action" 
                                      sx={{ opacity: 0.5 }}
                                    />
                                  </div>
                                </Box>
                              </ListItem>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </List>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </Paper>
        </Grid>
      </Grid>

      {/* Add Field Dialog */}
      <Dialog open={openAddFieldDialog} onClose={handleCloseAddFieldDialog}>
        <DialogTitle>Add New Lead Field</DialogTitle>
        <DialogContent>
          <TextField
            label="Field Name"
            name="name"
            value={newFieldData.name}
            onChange={handleNewFieldChange}
            fullWidth
            margin="normal"
            error={!!fieldErrors.name}
            helperText={fieldErrors.name}
          />
          <FormControl fullWidth margin="normal" error={!!fieldErrors.field_type}>
            <InputLabel>Field Type</InputLabel>
            <Select
              label="Field Type"
              name="field_type"
              value={newFieldData.field_type}
              onChange={handleNewFieldChange}
            >
              <MenuItem value="text">Text</MenuItem>
              <MenuItem value="number">Number</MenuItem>
              <MenuItem value="date">Date</MenuItem>
              <MenuItem value="boolean">Boolean</MenuItem>
              <MenuItem value="dropdown">Dropdown</MenuItem>
            </Select>
            {fieldErrors.field_type && (
              <FormHelperText>{fieldErrors.field_type}</FormHelperText>
            )}
          </FormControl>
          
          {newFieldData.field_type === 'dropdown' && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1">Add New Dropdown Option</Typography>
              <TextField
                label="New Option"
                value={newDropdownOption}
                onChange={(e) => setNewDropdownOption(e.target.value)}
                fullWidth
              />
              <Button
                sx={{ mt: 2 }}
                onClick={handleAddDropdownOption}
                variant="contained"
                disabled={!newDropdownOption.trim()}
              >
                Add Option
              </Button>

              {newFieldData.dropdown_options?.length > 0 && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="subtitle1">Existing Options</Typography>
                  <List sx={{ mt: 1, border: '1px solid #ccc', borderRadius: '4px', padding: 1 }}>
                    {newFieldData.dropdown_options.map((option, index) => (
                      <ListItem key={index} sx={{ borderBottom: '1px solid #eee' }}>
                        {editIndex === index ? (
                          <>
                            <TextField
                              value={editingOption}
                              onChange={(e) => setEditingOption(e.target.value)}
                              fullWidth
                            />
                            <IconButton onClick={saveDropdownOption} edge="end">
                              <CheckIcon />
                            </IconButton>
                            <IconButton onClick={cancelEditDropdownOption} edge="end">
                              <CloseIcon />
                            </IconButton>
                          </>
                        ) : (
                          <>
                            <ListItemText primary={option} />
                            <ListItemSecondaryAction>
                              <IconButton edge="end" onClick={() => startEditDropdownOption(index)}>
                                <EditIcon />
                              </IconButton>
                              <IconButton edge="end" onClick={() => handleRemoveDropdownOption(index)}>
                                <DeleteIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </>
                        )}
                      </ListItem>
                    ))}
                  </List>
                </Box>
              )}
            </Box>
          )}

          <FormControlLabel
            control={
              <Checkbox
                checked={newFieldData.required}
                onChange={(e) =>
                  setNewFieldData((prevData) => ({
                    ...prevData,
                    required: e.target.checked,
                  }))
                }
                name="required"
              />
            }
            label="Required"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddFieldDialog} color="secondary" variant="contained">
            Cancel
          </Button>
          <Button onClick={handleAddNewField} variant="contained">
            Add Field
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Field Dialog */}
      <Dialog open={openEditFieldDialog} onClose={handleCloseEditFieldDialog}>
        <DialogTitle>Edit Lead Field</DialogTitle>
        <DialogContent>
          <TextField
            label="Field Name"
            name="name"
            value={editFieldData.name}
            onChange={handleEditFieldChange}
            fullWidth
            margin="normal"
            error={!!fieldErrors.name}
            helperText={fieldErrors.name}
          />
          <TextField
            label="API Name"
            name="api_name"
            value={editFieldData.api_name}
            disabled
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth margin="normal" error={!!fieldErrors.field_type}>
            <InputLabel>Field Type</InputLabel>
            <Select
              label="Field Type"
              name="field_type"
              value={editFieldData.field_type}
              onChange={handleEditFieldChange}
            >
              <MenuItem value="text">Text</MenuItem>
              <MenuItem value="number">Number</MenuItem>
              <MenuItem value="date">Date</MenuItem>
              <MenuItem value="boolean">Boolean</MenuItem>
              <MenuItem value="dropdown">Dropdown</MenuItem>
            </Select>
            {fieldErrors.field_type && (
              <FormHelperText>{fieldErrors.field_type}</FormHelperText>
            )}
          </FormControl>

          {editFieldData.field_type === 'dropdown' && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1">Add New Dropdown Option</Typography>
              <TextField
                label="New Option"
                value={newDropdownOption}
                onChange={(e) => setNewDropdownOption(e.target.value)}
                fullWidth
              />
              <Button
                sx={{ mt: 2 }}
                onClick={handleEditDropdownOption}
                variant="contained"
                disabled={!newDropdownOption.trim()}
              >
                Add Option
              </Button>

              {editFieldData.dropdown_options?.length > 0 && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="subtitle1">Existing Options</Typography>
                  <List sx={{ mt: 1, border: '1px solid #ccc', borderRadius: '4px', padding: 1 }}>
                    {editFieldData.dropdown_options.map((option, index) => (
                      <ListItem key={index} sx={{ borderBottom: '1px solid #eee' }}>
                        {editIndex === index ? (
                          <>
                            <TextField
                              value={editingOption}
                              onChange={(e) => setEditingOption(e.target.value)}
                              fullWidth
                            />
                            <IconButton onClick={saveEditDropdownOption} edge="end">
                              <CheckIcon />
                            </IconButton>
                            <IconButton onClick={cancelEditDropdownOption} edge="end">
                              <CloseIcon />
                            </IconButton>
                          </>
                        ) : (
                          <>
                            <ListItemText primary={option} />
                            <ListItemSecondaryAction>
                              <IconButton edge="end" onClick={() => startEditDropdownEditOption(index)}>
                                <EditIcon />
                              </IconButton>
                              <IconButton edge="end" onClick={() => handleRemoveEditDropdownOption(index)}>
                                <DeleteIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </>
                        )}
                      </ListItem>
                    ))}
                  </List>
                </Box>
              )}
            </Box>
          )}

          <FormControlLabel
            control={
              <Checkbox
                checked={editFieldData.required}
                onChange={handleEditFieldChange}
                name="required"
              />
            }
            label="Required"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditFieldDialog} color="secondary" variant="contained">
            Cancel
          </Button>
          <Button onClick={handleUpdateField} variant="contained">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete the selected fields? This action cannot be undone and
            will delete all associated data.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="secondary" variant="contained">
            Cancel
          </Button>
          <Button onClick={handleDeleteFields} color="error" variant="contained">
            Confirm Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Feedback */}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* Save Configuration Button */}
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveLeadFieldConfig}
          disabled={false}
        >
          Save Configuration
        </Button>
      </Box>
    </Box>
  );
};

LeadFieldsConfiguration.propTypes = {
  campaignId: PropTypes.string.isRequired,
  campaignModelId: PropTypes.string.isRequired,
  selectedLeadFields: PropTypes.array.isRequired,
  setSelectedLeadFields: PropTypes.func.isRequired,
  accountId: PropTypes.string.isRequired,
  handleSaveLeadFieldConfig: PropTypes.func.isRequired,
};

export default LeadFieldsConfiguration;
