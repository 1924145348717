import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Divider,
  Button,
  IconButton,
  Paper,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Collapse,
  Menu,
  Badge,
  Tooltip as MuiTooltip,
  Drawer,
  Chip,
  Stack,
  Snackbar,
  Alert,
  Tooltip,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import FilterListIcon from '@mui/icons-material/FilterList';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ClearIcon from '@mui/icons-material/Clear';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllNotifications, markNotificationAsRead } from '../redux/notificationSlice';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTheme } from '@mui/material/styles';
import { showSnackbar } from '../redux/snackbarSlice';

const NotificationsPage = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications.allItems || []);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [activeFilterCount, setActiveFilterCount] = useState(0);
  // Filter states
  const [filters, setFilters] = useState({
    account: '',
    campaign: '',
    readStatus: 'all',
    priority: 'all',
    dateFrom: null,
    dateTo: null,
  });

  // Add state for pending filters
  const [pendingFilters, setPendingFilters] = useState({
    readStatus: 'all',
    priority: 'all',
    dateFrom: null,
    dateTo: null,
  });

  const [bulkActionsAnchorEl, setBulkActionsAnchorEl] = useState(null);

  useEffect(() => {
    dispatch(fetchAllNotifications());
  }, [dispatch]);

  const handleMarkAllAsRead = async () => {
    try {
      await Promise.all(selectedRows.map(notificationId => 
        dispatch(markNotificationAsRead(notificationId))
      ));
      
      dispatch(showSnackbar({
        message: `Successfully marked ${selectedRows.length} notification${selectedRows.length === 1 ? '' : 's'} as read`,
        severity: 'success'
      }));
      
      setSelectedRows([]);
      handleBulkActionsClose();
    } catch (error) {
      console.error('Error in handleMarkAllAsRead:', error);
      dispatch(showSnackbar({
        message: 'Failed to mark notifications as read. Please try again.',
        severity: 'error'
      }));
    }
  };

  const handleFilterChange = (field, value) => {
    setPendingFilters(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleApplyFilters = () => {
    setFilters(pendingFilters);
    setFiltersOpen(false);
  };

  const handleClearFilters = () => {
    const clearedFilters = {
      readStatus: 'all',
      priority: 'all',
      dateFrom: null,
      dateTo: null,
    };
    setPendingFilters(clearedFilters);
    setFilters(clearedFilters);
  };

  // Add handlers for bulk actions menu
  const handleBulkActionsClick = (event) => {
    setBulkActionsAnchorEl(event.currentTarget);
  };

  const handleBulkActionsClose = () => {
    setBulkActionsAnchorEl(null);
  };

  // Calculate active filter count
  useEffect(() => {
    let count = 0;
    
    if (filters.readStatus !== 'all') count++;
    if (filters.priority !== 'all') count++;
    if (filters.dateFrom) count++;
    if (filters.dateTo) count++;
    
    setActiveFilterCount(count);
  }, [filters]);

  const filteredNotifications = notifications.filter(notification => {
    if (filters.readStatus !== 'all' && notification.is_read !== (filters.readStatus === 'read')) return false;
    if (filters.priority !== 'all' && notification.is_high_priority !== (filters.priority === 'high')) return false;
    if (filters.dateFrom && new Date(notification.created_at) < filters.dateFrom) return false;
    if (filters.dateTo && new Date(notification.created_at) > filters.dateTo) return false;
    return true;
  }).map(notification => ({
    ...notification,
    id: notification.id || Math.random().toString(), // Ensure each row has an id
  }));

  const columns = [
    {
      field: 'is_read',
      headerName: 'Status',
      width: 100,
      renderCell: (params) => (
        <Typography
          sx={{
            fontWeight: params.value ? 'normal' : 'bold',
            color: params.value ? 'text.secondary' : 'text.primary',
          }}
        >
          {params.value ? 'Read' : 'Unread'}
        </Typography>
      ),
    },
    {
      field: 'is_high_priority',
      headerName: 'Priority',
      width: 100,
      renderCell: (params) => (
        <IconButton size="small">
          {params.value ? <StarIcon color="warning" /> : <StarBorderIcon />}
        </IconButton>
      ),
    },
    {
      field: 'message',
      headerName: 'Message',
      flex: 1,
      renderCell: (params) => (
        <Typography
          sx={{
            fontWeight: params.row.is_read ? 'normal' : 'bold',
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: 'created_at',
      headerName: 'Date',
      width: 200,
      renderCell: (params) => new Date(params.value).toLocaleString(),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      renderCell: (params) => (
        <Tooltip title={params.row.is_read ? "Already read" : "Mark as read"}>
          <span>
            <IconButton
              onClick={(e) => {
                e.stopPropagation(); // Prevent row selection
                handleMarkSingleAsRead(params.row.id);
              }}
              disabled={params.row.is_read}
              size="small"
              color="primary"
            >
              <CheckCircleOutlineIcon />
            </IconButton>
          </span>
        </Tooltip>
      ),
    },
  ];

  const handleMarkSingleAsRead = async (notificationId) => {
    try {
      await dispatch(markNotificationAsRead(notificationId));
      dispatch(showSnackbar({
        message: 'Notification marked as read',
        severity: 'success'
      }));
    } catch (error) {
      console.error('Error marking notification as read:', error);
      dispatch(showSnackbar({
        message: 'Failed to mark notification as read. Please try again.',
        severity: 'error'
      }));
    }
  };

  const handleRowClick = async (params) => {
    if (!params.row.is_read) {
      try {
        await dispatch(markNotificationAsRead(params.row.id));
        dispatch(showSnackbar({
          message: 'Notification marked as read',
          severity: 'success'
        }));
      } catch (error) {
        console.error('Error in handleRowClick:', error);
        dispatch(showSnackbar({
          message: 'Failed to mark notification as read. Please try again.',
          severity: 'error'
        }));
      }
    }
  };

  return (
    <Box p={3}>
      <Grid container spacing={3}>
        {/* Header Section - unchanged */}
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography 
              variant="h4" 
              sx={{ 
                color: theme.palette.text.primary,
                fontWeight: 'bold' 
              }}
            >
              Notifications
            </Typography>
            <MuiTooltip title="Toggle Filters" arrow placement="left">
              <IconButton onClick={() => setFiltersOpen(!filtersOpen)}>
                <Badge badgeContent={activeFilterCount} color="primary">
                  <FilterListIcon />
                </Badge>
              </IconButton>
            </MuiTooltip>
          </Box>
        </Grid>

        {/* Bulk Actions Section */}
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end" mb={2}>
            <Button
              variant="contained"
              onClick={handleBulkActionsClick}
              endIcon={<MoreVertIcon />}
              disabled={selectedRows.length === 0}
            >
              Bulk Actions
            </Button>
            <Menu
              anchorEl={bulkActionsAnchorEl}
              open={Boolean(bulkActionsAnchorEl)}
              onClose={handleBulkActionsClose}
            >
              <MenuItem 
                onClick={() => {
                  handleMarkAllAsRead();
                  handleBulkActionsClose();
                }}
              >
                Mark Selected as Read
              </MenuItem>
            </Menu>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ height: 600, width: '100%' }}>
          <DataGrid
            rows={filteredNotifications}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10, 25, 50]}
            checkboxSelection
            initialState={{
              pagination: {
                paginationModel: { pageSize: 10 },
              },
            }}
            pageSizeOptions={[10, 25, 50]}
            onRowSelectionModelChange={(newSelection) => {
              setSelectedRows(newSelection);
            }}
            rowSelectionModel={selectedRows}
            onRowClick={handleRowClick}
            getRowClassName={(params) => 
              !params.row.is_read ? 'unread-row' : ''
            }
          />
          </Paper>
        </Grid>
      </Grid>

      {/* Filter Drawer */}
      <Drawer
        anchor="right"
        open={filtersOpen}
        onClose={() => setFiltersOpen(false)}
        PaperProps={{
          sx: {
            width: 320,
            p: 3,
            backgroundColor: theme.palette.background.paper,
          },
        }}
      >
        <Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
            <Typography variant="h6">Filters</Typography>
            <MuiTooltip title="Hide Filters">
              <IconButton onClick={() => setFiltersOpen(false)} size="small">
                <ClearIcon />
              </IconButton>
            </MuiTooltip>
          </Box>

          <Stack spacing={3}>
            <FormControl fullWidth>
              <InputLabel>Read Status</InputLabel>
              <Select
                value={pendingFilters.readStatus}
                onChange={(e) => handleFilterChange('readStatus', e.target.value)}
                label="Read Status"
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="read">Read</MenuItem>
                <MenuItem value="unread">Unread</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel>Priority</InputLabel>
              <Select
                value={pendingFilters.priority}
                onChange={(e) => handleFilterChange('priority', e.target.value)}
                label="Priority"
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="high">High Priority</MenuItem>
                <MenuItem value="normal">Normal Priority</MenuItem>
              </Select>
            </FormControl>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="From Date"
                value={pendingFilters.dateFrom}
                onChange={(newValue) => handleFilterChange('dateFrom', newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
              <DatePicker
                label="To Date"
                value={pendingFilters.dateTo}
                onChange={(newValue) => handleFilterChange('dateTo', newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
                minDate={pendingFilters.dateFrom}
              />
            </LocalizationProvider>
          </Stack>

          {/* Active Filters Display */}
          {activeFilterCount > 0 && (
            <Box mt={3}>
              <Typography variant="subtitle2" gutterBottom>
                Active Filters:
              </Typography>
              <Stack direction="row" spacing={1} flexWrap="wrap">
                {filters.readStatus !== 'all' && (
                  <Chip
                    key="readStatus"
                    label={`Status: ${filters.readStatus}`}
                    onDelete={() => handleFilterChange('readStatus', 'all')}
                    size="small"
                    sx={{ mt: 1 }}
                  />
                )}
                {filters.priority !== 'all' && (
                  <Chip
                    key="priority"
                    label={`Priority: ${filters.priority}`}
                    onDelete={() => handleFilterChange('priority', 'all')}
                    size="small"
                    sx={{ mt: 1 }}
                  />
                )}
                {filters.dateFrom && (
                  <Chip
                    key="dateFrom"
                    label={`From: ${filters.dateFrom.toLocaleDateString()}`}
                    onDelete={() => handleFilterChange('dateFrom', null)}
                    size="small"
                    sx={{ mt: 1 }}
                  />
                )}
                {filters.dateTo && (
                  <Chip
                    key="dateTo"
                    label={`To: ${filters.dateTo.toLocaleDateString()}`}
                    onDelete={() => handleFilterChange('dateTo', null)}
                    size="small"
                    sx={{ mt: 1 }}
                  />
                )}
              </Stack>
            </Box>
          )}

          {/* Apply and Clear Filters Buttons */}
          <Box mt={3} display="flex" gap={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleApplyFilters}
              fullWidth
            >
              Apply Filters
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClearFilters}
              startIcon={<ClearIcon />}
              fullWidth
            >
              Clear
            </Button>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};

export default NotificationsPage;
