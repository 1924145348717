// src/redux/notificationSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../services/authService';

// Existing fetchNotifications
export const fetchNotifications = createAsyncThunk(
  'notifications/fetchNotifications',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/crm/notifications/');
      return response.data;
    } catch (error) {
      // Check if the error is due to unauthorized access
      if (error.response && error.response.status === 401) {
        // Optionally, you can dispatch a logout action here
        // dispatch(logOut());
      }
      return rejectWithValue(error.response?.data || 'Failed to fetch notifications');
    }
  }
);

// New thunk to fetch all notifications
export const fetchAllNotifications = createAsyncThunk(
  'notifications/fetchAllNotifications',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/crm/notifications/all/');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// New thunk to mark a notification as read
export const markNotificationAsRead = createAsyncThunk(
  'notifications/markNotificationAsRead',
  async (notificationId, { rejectWithValue }) => {
    try {
      const response = await api.patch(`/crm/notifications/${notificationId}/`, { is_read: true });
      return response.data; // Assuming the API returns the updated notification
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Add new thunk for unread notifications
export const fetchUnreadNotifications = createAsyncThunk(
  'notifications/fetchUnread',
  async () => {
    const response = await api.get('/crm/notifications/unread/');
    return response.data;
  }
);

const notificationSlice = createSlice({
  name: 'notifications',
  initialState: {
    items: [], // for unread notifications in popover
    allItems: [], // for all notifications in the page
    loading: false,
    error: null,
  },
  reducers: {
    addNotification(state, action) {
      state.items.unshift(action.payload);
      state.allItems.unshift(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotifications.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload;
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchAllNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.allItems = action.payload;
        state.error = null;
      })
      .addCase(fetchAllNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(markNotificationAsRead.fulfilled, (state, action) => {
        const updatedNotification = action.payload;
        // Update in items
        const index = state.items.findIndex((n) => n.id === updatedNotification.id);
        if (index !== -1) {
          state.items[index].is_read = updatedNotification.is_read;
        }
        // Update in allItems
        const allIndex = state.allItems.findIndex((n) => n.id === updatedNotification.id);
        if (allIndex !== -1) {
          state.allItems[allIndex].is_read = updatedNotification.is_read;
        }
      })
      .addCase(markNotificationAsRead.rejected, (state, action) => {
        state.error = action.payload;
      })
      // Add cases for unread notifications
      .addCase(fetchUnreadNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUnreadNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
        state.error = null;
      })
      .addCase(fetchUnreadNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { addNotification } = notificationSlice.actions;
export default notificationSlice.reducer;
